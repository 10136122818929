import { ContactData } from "feathers-backend";

export const initializeContactData = (contact?: ContactData): ContactData => ({
  firstName: contact?.firstName || "",
  lastName: contact?.lastName || "",
  email: contact?.email || "",
  phone: contact?.phone || "",
  title: contact?.title || "",
  teamId: contact?.teamId || {},
});
