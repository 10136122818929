import { useContext, useEffect, useCallback } from "react";
import { Form, Formik } from "formik";
import { Button, Tab, Tabs } from "@heroui/react";
import { Board, Opportunity, OpportunityData } from "feathers-backend";
import { client } from "../../../utils/Client";
import { DataContext } from "../../../contexts/DataContext";
import { ModalContext } from "../../../contexts/ModalContext";
import { UserContext } from "../../../contexts/UserContext";
import { OpportunityProvider, useOpportunity } from "../../../contexts/OpportunityContext";
import ViewOpportunity from "./ViewOpportunity";
import OpportunityProcess from "./OpportunityProcess";
import OpportunityDetails from "./OpportunityDetails";
import SelectCustomerForOpportunity from "../Fields/SelectCustomerForOpportunity";
import SelectContactForOpportunity from "../Fields/SelectContactForOpportunity";
import OpportunityActivity from "./OpportunityActivity";
import OpportunityContact from "./OpportunityContact";
import OpportunityCustomer from "./OpportunityCustomer";
import SelectAssignee from "../Fields/SelectAssignee";
import { initializeOpportunityData } from "../../../helpers/initializeOpportunityData";
import { AuthContext } from "../../../contexts/AuthContext";
import Oneflow from "../Fields/Oneflow";
import RexorProject from "../Fields/RexorProject";

type Props = {
  opportunity: Opportunity;
  board?: Board | null;
};

function OpportunityFormContent({ board }: { board?: Board | null }) {
  const { me } = useContext(AuthContext);
  const { opportunity, setOpportunity } = useOpportunity();
  const { closeModal } = useContext(ModalContext);
  const { lang } = useContext(UserContext);
  const { opportunities } = useContext(DataContext);

  useEffect(() => {
    const updatedOpportunity = opportunities.find((op) => op._id === opportunity?._id);
    if (updatedOpportunity) {
      setOpportunity(updatedOpportunity);
    }
  }, [opportunities, opportunity._id]);

  // Use our initializer to strip out _id and other internal fields
  const handleSave = useCallback(
    async (values: OpportunityData) => {
      // Here, values is of type OpportunityData (without _id)
      await client.service("opportunities").patch(opportunity._id.toString(), values);
      setOpportunity({ ...opportunity, ...values }); // Update context state
    },
    [opportunity, setOpportunity]
  );

  return (
    <div>
      <Formik initialValues={initializeOpportunityData(opportunity)} onSubmit={handleSave} enableReinitialize>
        {({ values, dirty, setFieldValue }) => (
          <Form>
            <ViewOpportunity />
            <div className="flex justify-between my-4">
              <div className="w-full"></div>
              <SelectAssignee
                name="assignedTo"
                onChange={(field, newValue) => setFieldValue(field, newValue)}
                value={typeof values.assignedTo === "string" ? values.assignedTo : undefined}
              />
            </div>
            <Tabs fullWidth aria-label="Options">
              {board && (
                <Tab key="board" title={board.name}>
                  <OpportunityProcess board={board} />
                </Tab>
              )}
              <Tab key="details" title={lang("Details")}>
                <OpportunityDetails />
              </Tab>
              {me.currentTeam.integrations?.oneflow?.enabled && !board && (
                <Tab key="oneflow" title={lang("Oneflow")}>
                  <Oneflow />
                </Tab>
              )}
              {me.currentTeam.integrations?.rexor && !values.rexorId && !board && (
                <Tab key="rexor" title={lang("Rexor")}>
                  <RexorProject />
                </Tab>
              )}
              <Tab key="customer" title={lang("Customer")}>
                <SelectCustomerForOpportunity />
                {!values.customerId && <OpportunityCustomer />}
              </Tab>
              <Tab key="contact" title={lang("Contact")}>
                <SelectContactForOpportunity />
                {!values.contactId && <OpportunityContact />}
              </Tab>
              <Tab key="activity" title={lang("Activity")}>
                <OpportunityActivity />
              </Tab>
            </Tabs>
            <div className="flex justify-end gap-2 mt-4">
              <Button onPress={closeModal} variant="light">
                {lang("Close")}
              </Button>
              <Button type="submit" className="disabled:hidden" color="primary" disabled={!dirty}>
                {lang("Save")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

// Wrap OpportunityForm with the provider
export default function OpportunityForm({ opportunity, board }: Props) {
  return (
    <OpportunityProvider initialOpportunity={opportunity}>
      <OpportunityFormContent board={board} />
    </OpportunityProvider>
  );
}
