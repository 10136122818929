import { Customer } from "feathers-backend";
import { useCustomer } from "./CustomerShow";
import { client } from "../../utils/Client";
import { useFormik } from "formik";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
  Textarea,
} from "@heroui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import SelectAssignee from "../../components/Forms/Fields/SelectAssignee";
import { ModalContext } from "../../contexts/ModalContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataContext";
import BillingMethods from "../../components/Forms/Fields/BillingMethods";

export default function CustomerDetails() {
  const { customer } = useCustomer() || ({} as Customer);
  const { closeModal } = useContext(ModalContext);
  const { lang } = useContext(UserContext);
  const { customers } = useContext(DataContext);
  const { me } = useContext(AuthContext);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(generateValues(customer));

  // Function that returns a list of values for the formik form
  function generateValues(customer: Customer | null) {
    return {
      name: customer?.name || "",
      organizationNumber: customer?.organizationNumber || "",
      customCustomerId: customer?.customCustomerId || "",
      website: customer?.website || "",
      notes: customer?.notes || "",
      billingAddress: {
        street: customer?.billingAddress?.street || "",
        city: customer?.billingAddress?.city || "",
        postalCode: customer?.billingAddress?.postalCode || "",
        country: customer?.billingAddress?.country || "",
        billingEmail: customer?.billingAddress?.billingEmail || "",
        billingMethod: customer?.billingAddress?.billingMethod || "",
        gln: customer?.billingAddress?.gln || "",
      },
      visitingAddress: {
        street: customer?.visitingAddress?.street || "",
        city: customer?.visitingAddress?.city || "",
        postalCode: customer?.visitingAddress?.postalCode || "",
        country: customer?.visitingAddress?.country || "",
      },
      assignedTo: customer?.assignedTo || undefined,
    };
  }

  // Useeffect on customers and if this specific customer is updated update the formik values but only if this customer has been updated
  useEffect(() => {
    if (customers && customer) {
      const updatedCustomer = customers.find((c) => c._id === customer._id);
      if (updatedCustomer) {
        setInitialValues(generateValues(updatedCustomer));
      }
    }
  }, [customers, customer]);

  const deleteCustomer = async () => {
    if (customer) {
      await client.service("customers").remove(customer?._id.toString());

      // Delete the customer contacts based on the Id´s from customer.contacts
      customer.contacts?.forEach(async (contactId) => {
        await client.service("contacts").remove(contactId.toString());
      });

      navigate("/customers");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      if (customer) {
        client.service("customers").patch(customer._id.toString(), values);
      } else {
        // Check if there already is a customer with the same organization number from customers
        const existingCustomer = customers.find((c) => c.organizationNumber === values.organizationNumber);

        // Throw an error if a customer with the same organization number already exists
        if (existingCustomer && values.organizationNumber !== "") {
          formik.setErrors({ organizationNumber: "A customer with this organization number already exists." });
          return;
        }

        client.service("customers").create(values);
        closeModal();
      }
    },
  });

  return (
    <div className="">
      <form onSubmit={formik.handleSubmit}>
        <div className="col-span-2 mb-2">{lang("Details")}</div>
        <div className="grid grid-cols-2 gap-2">
          <Input
            label={lang("Customer name")}
            name="name"
            type="text"
            value={formik.values.name ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Organization number")}
            name="organizationNumber"
            type="text"
            value={formik.values.organizationNumber ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Custom ID")}
            name="customCustomerId"
            type="text"
            value={formik.values.customCustomerId ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Website")}
            name="website"
            type="text"
            value={formik.values.website ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="col-span-2 mt-2">
            <Textarea
              label={lang("Notes")}
              name="notes"
              type="text"
              value={formik.values.notes ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-span-2 mt-2">{lang("Billing address")}</div>
          <Input
            label={lang("Street")}
            name="billingAddress.street"
            type="text"
            value={formik.values.billingAddress?.street ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("City")}
            name="billingAddress.city"
            type="text"
            value={formik.values.billingAddress?.city ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Postal code")}
            name="billingAddress.postalCode"
            type="text"
            value={formik.values.billingAddress?.postalCode ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Country")}
            name="billingAddress.country"
            type="text"
            value={formik.values.billingAddress?.country ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Billing Email")}
            name="billingAddress.billingEmail"
            type="text"
            value={formik.values.billingAddress?.billingEmail ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <BillingMethods
            value={formik.values.billingAddress?.billingMethod ?? ""}
            onChange={formik.handleChange}
            name="billingAddress.billingMethod"
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("GLN")}
            name="billingAddress.gln"
            type="text"
            value={formik.values.billingAddress?.gln ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="col-span-2 mt-2">{lang("Visiting address")}</div>
          <Input
            label={lang("Street")}
            name="visitingAddress.street"
            type="text"
            value={formik.values.visitingAddress?.street ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("City")}
            name="visitingAddress.city"
            type="text"
            value={formik.values.visitingAddress?.city ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Postal code")}
            name="visitingAddress.postalCode"
            type="text"
            value={formik.values.visitingAddress?.postalCode ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label={lang("Country")}
            name="visitingAddress.country"
            type="text"
            value={formik.values.visitingAddress?.country ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <SelectAssignee
            name="assignedTo"
            onChange={(name, value) => formik.setFieldValue(name, value)}
            value={formik.values.assignedTo?.toString() || undefined}
          />
        </div>
        <div>
          {formik.errors.organizationNumber && (
            <div className="my-3 text-red-500"> {formik.errors.organizationNumber as any} </div>
          )}
        </div>
        <div className="flex justify-end gap-2 my-4">
          <ButtonGroup variant="solid" color="primary">
            <Button type="submit" color="primary">
              {lang("Save")}
            </Button>
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Button className="border-l border-ash " isIconOnly>
                  <ChevronDownIcon className="w-4 h-4" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Customer options" className="max-w-[300px]">
                <DropdownItem onPress={() => formik.submitForm()} key="save">
                  {lang("Save")}
                </DropdownItem>
                {me &&
                  me.role === "admin" &&
                  ((
                    <DropdownItem onPress={() => deleteCustomer()} key="delete">
                      {lang("Delete")}
                    </DropdownItem>
                  ) as any)}
              </DropdownMenu>
            </Dropdown>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
}
