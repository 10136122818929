import { Button, Checkbox, Input } from "@heroui/react";
import { Column } from "feathers-backend";
import { useFormik } from "formik";
import { client } from "../../utils/Client";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { DataContext } from "../../contexts/DataContext";
import { ModalContext } from "../../contexts/ModalContext";
import { UserContext } from "../../contexts/UserContext";
import { AuthContext } from "../../contexts/AuthContext";

type KanBanColumnFormProps = {
  column: Column;
};

type CustomfieldItem = {
  _id: string;
  name: string;
  description: string;
  type: string;
  checked: boolean;
};

export default function KanBanColumnForm({ column }: KanBanColumnFormProps) {
  const { closeModal } = useContext(ModalContext);
  const { me } = useContext(AuthContext);
  const { customfields, columns } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [customfieldArray, setCustomfieldArray] = useState<CustomfieldItem[]>([]);

  useEffect(() => {
    const initialCustomfieldArray = [];

    const boardColumns = columns.filter((col) => col.boardId === column.boardId);
    const getUsedCustomFields = () => {
      const usedFields = new Set();
      boardColumns.forEach((col) => {
        if (col._id !== column._id) {
          // Exclude the current column
          col.customfields.forEach((fieldId) => usedFields.add(fieldId));
        }
      });
      return usedFields;
    };
    const usedCustomFields = getUsedCustomFields();

    // Add the customfields from the column in the correct order
    for (const id of column.customfields) {
      const cf = customfields.find((cf) => cf._id.toString() === id);
      if (cf) {
        initialCustomfieldArray.push({
          _id: cf._id.toString(),
          name: cf.name,
          description: cf.description || "",
          type: cf.type,
          checked: true,
        });
      }
    }
    // Append any remaining customfields from the global list that aren't already in the column
    for (const cf of customfields) {
      if (!column.customfields.includes(cf._id.toString()) && !usedCustomFields.has(cf._id.toString())) {
        initialCustomfieldArray.push({
          _id: cf._id.toString(),
          name: cf.name,
          description: cf.description || "",
          type: cf.type,
          checked: false,
        });
      }
    }
    setCustomfieldArray(initialCustomfieldArray);
  }, [column, customfields]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    setCustomfieldArray((prevArray) => {
      const newCustomfieldArray = [...prevArray];
      const [reorderedItem] = newCustomfieldArray.splice(result.source.index, 1);
      newCustomfieldArray.splice(result.destination.index, 0, reorderedItem);
      return newCustomfieldArray; // Return the updated array directly.
    });
  };

  const handleCheckboxChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomfieldArray((prevArray) => {
      const newCustomfieldArray = [...prevArray];
      newCustomfieldArray[index].checked = event.target.checked;
      return newCustomfieldArray;
    });
  };

  const formik = useFormik({
    initialValues: {
      ...column,
      customfields: customfieldArray,
    },
    onSubmit: async (values) => {
      const checkedCustomfields = customfieldArray.filter((cf) => cf.checked).map((cf) => cf._id);
      const updatedColumn = {
        ...values,
        customfields: checkedCustomfields,
      };
      await client.service("columns").patch(column._id.toString(), updatedColumn);
      closeModal();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        label="Column name"
        name="name"
        type="text"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <div className="">
        <div className="mt-4 mb-2 font-semibold">Custom fields</div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable isCombineEnabled={false} droppableId="customfields">
            {(provided) => (
              <div className="flex flex-col" ref={provided.innerRef}>
                {customfieldArray.map((c, index) => (
                  <Draggable key={c._id.toString()} draggableId={c._id.toString()} index={index}>
                    {(provided) => (
                      <div
                        className="flex px-4 py-2 my-1 rounded-lg bg-card hover:bg-ash"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Checkbox
                          name={`customfields[${index}]`}
                          isSelected={c.checked}
                          onChange={(event) => handleCheckboxChange(index, event)}
                        />
                        <div className="ml-2">
                          <div className="text-sm">{c.name}</div>
                          <div className="text-xs text-placehold">{c.description}</div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="flex justify-end gap-2 mt-6 mb-3">
        {column && column.items?.length < 1 && (
          <Button
            onPress={() => {
              client.service("columns").remove(column._id.toString());
              closeModal();
            }}
            color="danger"
          >
            {lang("Delete")}
          </Button>
        )}
        <Button variant="light" onPress={closeModal}>
          {lang("Close")}
        </Button>
        <Button color="primary" type="submit">
          {column ? lang("Save") : lang("Skapa")}
        </Button>
      </div>
    </form>
  );
}
