import { useContext, useCallback } from "react";
import { Autocomplete, AutocompleteItem } from "@heroui/react";
import { DataContext } from "../../../contexts/DataContext";
import { useFormikContext } from "formik";
import { OpportunityPatch } from "feathers-backend";
import { UserContext } from "../../../contexts/UserContext";

export default function SelectContactForOpportunity() {
  const { lang } = useContext(UserContext);
  const { contacts } = useContext(DataContext);
  const { values, setFieldValue } = useFormikContext<OpportunityPatch>();

  const sortedContacts = contacts
    ? contacts
        .map((contact) => ({
          key: contact._id.toString(), // ensure key is a string as required by Heroui
          label: contact.firstName + " " + contact.lastName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  // Handle selection change. When the user clears the selection, key will be null.
  const onSelectionChange = useCallback(
    (key: React.Key | null) => {
      setFieldValue("contactId", key ? key.toString() : null);
    },
    [setFieldValue]
  );

  return (
    <div className="flex flex-wrap gap-4 my-4 md:flex-nowrap">
      {sortedContacts.length > 0 && (
        <Autocomplete
          // Use uncontrolled API for search filtering
          defaultItems={sortedContacts}
          defaultSelectedKey={values.contactId?.toString() || ""}
          label="Contact"
          placeholder={lang("Select contact")}
          isClearable
          onSelectionChange={onSelectionChange}
        >
          {(item) => <AutocompleteItem key={item.key}>{item.label}</AutocompleteItem>}
        </Autocomplete>
      )}
    </div>
  );
}
