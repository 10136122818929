import { useContext, useEffect, useState } from "react";
import { Chip } from "@heroui/react";
import { DataContext } from "../../contexts/DataContext";
import { Tag } from "feathers-backend";

type GetTagsProps = {
  tagsList: string[]; // Assuming tagsList is an array of tag IDs
};

export default function Tags({ tagsList }: GetTagsProps) {
  const { tags } = useContext(DataContext);
  const [matchedTags, setMatchedTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (!tags || !tagsList) return;
    const matchedTagsArray = tags.filter((tag) => tagsList.includes(tag._id.toString()));
    setMatchedTags(matchedTagsArray);
  }, [tags, tagsList]);

  return (
    <div className="flex flex-wrap gap-2">
      {matchedTags.map((tag: any) => (
        <Chip
          startContent={<div style={{ backgroundColor: tag.color }} className="w-2 h-2 rounded-full" />}
          size="sm"
          style={{ color: tag.color }}
          key={tag._id.toString()}
        >
          {tag.text}
        </Chip>
      ))}
    </div>
  );
}
