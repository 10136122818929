import { useContext, useEffect, useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@heroui/react";
import { DataContext } from "../../contexts/DataContext";
import Avatar from "../../components/Avatar";
import { UserContext } from "../../contexts/UserContext";
import { Opportunity } from "feathers-backend";

export default function LatestWon() {
  const { users, opportunities } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [latestWon, setLatestWon] = useState<any[]>([]);

  useEffect(() => {
    const latestWonOpportunities = opportunities
      .filter((opportunity) => opportunity.status === "closedWon")
      .sort((a, b) => (b.closedDate || 0) - (a.closedDate || 0))
      .slice(0, 5);

    const latestWonWithUsers = latestWonOpportunities.map((opportunity) => {
      const user = users.find((u) => u._id === opportunity.closedBy);
      return {
        ...opportunity,
        userName: user ? `${user.firstName} ${user.lastName}` : "Unknown",
      };
    });

    setLatestWon(latestWonWithUsers);
  }, [opportunities, users]);

  return (
    <Table aria-label="Latest Won Opportunities">
      <TableHeader>
        <TableColumn className="uppercase">{lang("New deals")}</TableColumn>
        <TableColumn className="uppercase">{lang("Closed by")}</TableColumn>
      </TableHeader>
      <TableBody emptyContent={lang("No closed opportunities")}>
        {latestWon.map((opportunity) => (
          <TableRow key={opportunity._id.toString()}>
            <TableCell>
              <div className="flex mt-1">
                <div className="my-auto ml-2 mr-4">
                  <Avatar
                    name={opportunity.displayName ?? ""}
                    status="closedWon"
                    radius="md"
                    size="md"
                    image={opportunity.avatar}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="text-white ">
                    {opportunity.contact?.firstName} {opportunity.contact?.lastName}
                  </div>
                  <div className="text-sm text-placehold">
                    {opportunity.customer?.name || opportunity.contact?.email}
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell>{opportunity.userName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
