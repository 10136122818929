import { Input, Textarea } from "@heroui/react";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { useFormikContext } from "formik";
import { OpportunityPatch } from "feathers-backend";

export default function OpportunityContact() {
  const { lang } = useContext(UserContext);
  const { values, handleChange, handleBlur } = useFormikContext<OpportunityPatch>();

  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <Input
          label={lang("First name")}
          name="contact.firstName"
          type="text"
          value={values.contact?.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Last name")}
          name="contact.lastName"
          type="text"
          value={values.contact?.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Email")}
          name="contact.email"
          type="email"
          value={values.contact?.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Phone")}
          name="contact.phone"
          type="text"
          value={values.contact?.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Title")}
          name="contact.title"
          type="text"
          value={values.contact?.title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="col-span-2">
          <Textarea
            label={lang("Notes")}
            name="contact.notes"
            type="text"
            value={values.contact?.notes}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  );
}
