import { useContext, useEffect, useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@heroui/react";
import { DataContext } from "../../contexts/DataContext";
import Avatar from "../../components/Avatar";
import { UserContext } from "../../contexts/UserContext";
import { AuthContext } from "../../contexts/AuthContext";
import moment from "moment";
import "moment/locale/sv";
import { Opportunity } from "feathers-backend";

export default function NextFollowUps() {
  moment.locale("sv");
  const { me } = useContext(AuthContext);
  const { users, opportunities } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [upcomingFollowUps, setUpcomingFollowUps] = useState<any[]>([]);

  useEffect(() => {
    const userOpportunities = opportunities
      .filter(
        (opportunity) => opportunity.status === "followup" && opportunity.assignedTo?.toString() === me._id.toString()
      )
      .sort((a, b) => (a.followUpDate || 0) - (b.followUpDate || 0))
      .slice(0, 5);

    const upcomingWithUsers = userOpportunities.map((opportunity) => {
      const user = users.find((u) => u._id === opportunity.assignedTo);
      return {
        ...opportunity,
        userName: user ? `${user.firstName} ${user.lastName}` : "Unknown",
      };
    });

    setUpcomingFollowUps(upcomingWithUsers);
  }, [opportunities, users, me]);

  return (
    <Table aria-label="Next Upcoming Follow-ups">
      <TableHeader>
        <TableColumn className="uppercase">{lang("Opportunity")}</TableColumn>
        <TableColumn className="uppercase">{lang("Follow-up")}</TableColumn>
      </TableHeader>
      <TableBody emptyContent={lang("No follow-ups")}>
        {upcomingFollowUps.map((opportunity: Opportunity) => (
          <TableRow key={opportunity._id.toString()}>
            <TableCell>
              <div className="flex mt-1">
                <div className="my-auto ml-2 mr-4">
                  <Avatar
                    name={opportunity.displayName ?? ""}
                    status={"followup"}
                    radius="md"
                    size="md"
                    image={opportunity.avatar}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="text-white ">
                    {opportunity.contact?.firstName} {opportunity.contact?.lastName}
                  </div>
                  <div className="text-sm text-placehold">
                    {opportunity.customer?.name || opportunity.contact?.email}
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell>{moment(opportunity.followUpDate).fromNow()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
