import { useContext, useCallback, useMemo } from "react";
import { Autocomplete, AutocompleteItem } from "@heroui/react";
import { DataContext } from "../../../contexts/DataContext";
import { useFormikContext } from "formik";
import { OpportunityPatch } from "feathers-backend";
import { UserContext } from "../../../contexts/UserContext";

export default function SelectCustomerForOpportunity() {
  const { lang } = useContext(UserContext);
  const { customers } = useContext(DataContext);
  const { values, setFieldValue } = useFormikContext<OpportunityPatch>();

  // Memoize the sorted customer list so it doesn't recalc on every render
  const sortedCustomers = useMemo(() => {
    return customers
      ? customers
          .map((customer) => ({
            key: customer._id.toString(),
            label: customer.name || "",
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  }, [customers]);

  // Update the field value when the selection changes
  const onSelectionChange = useCallback(
    (key: React.Key | null) => {
      setFieldValue("customerId", key ? key.toString() : null);
    },
    [setFieldValue]
  );

  return (
    <div className="flex flex-wrap gap-4 my-4 md:flex-nowrap">
      {sortedCustomers.length > 0 && (
        <Autocomplete
          isVirtualized
          defaultItems={sortedCustomers}
          // Use undefined if no value so we don't trigger updates on every render
          defaultSelectedKey={values.customerId ? values.customerId.toString() : undefined}
          label="Customer"
          placeholder={lang("Select customer")}
          isClearable
          onSelectionChange={onSelectionChange}
        >
          {(item) => (
            <AutocompleteItem key={item.key} textValue={item.label}>
              {item.label}
            </AutocompleteItem>
          )}
        </Autocomplete>
      )}
    </div>
  );
}
