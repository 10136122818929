import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button } from "@heroui/react";
import { useContext, useState, useMemo, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import SourcesForm from "./SourcesForm";
import { UserContext } from "../../contexts/UserContext";
import { AuthContext } from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataContext";

export default function Sources() {
  const { me } = useContext(AuthContext);
  const { teams } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState<string | null>(null);

  const getTitle = () => {
    if (!open) return "Sources";
    if (open && !selectedSource) return "Add new source";
    if (open && selectedSource) return "Edit source";
  };

  const columns = [{ label: "Source", key: "source" }];

  // Compute currentTeam from the teams array (which updates when setTeams is called)
  const currentTeam = useMemo(() => {
    if (me && me.currentTeam) {
      const found = teams.find((team) => team._id.toString() === me.currentTeam._id.toString());
      return found || me.currentTeam;
    }
    return null;
  }, [teams, me]);

  // Convert the sources (array of strings) to an array of objects with key and source properties.
  const sourceItems = useMemo(() => {
    const sources = currentTeam?.sources || [];
    return sources.map((s: string) => ({ key: s, source: s }));
  }, [currentTeam]);

  // Reset selectedSource when the form closes.
  useEffect(() => {
    if (!open) {
      setSelectedSource(null);
    }
  }, [open]);

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2>{getTitle()}</h2>
        <Button
          onPress={() => {
            if (open) setOpen(false);
            else {
              setSelectedSource(null);
              setOpen(true);
            }
          }}
          color={open ? "default" : "primary"}
          endContent={!open && <PlusIcon className="w-5 h-5" />}
        >
          {open ? "Go back" : "Add New"}
        </Button>
      </div>
      {open ? (
        <SourcesForm selectedSource={selectedSource} setOpen={setOpen} />
      ) : (
        <Table
          classNames={{
            wrapper: "px-0 shadow-none",
            tr: "hover:bg-ash cursor-pointer",
          }}
          aria-label="Sources table"
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody emptyContent={"No sources added"} items={sourceItems}>
            {(item: any) => (
              <TableRow
                onClick={() => {
                  setSelectedSource(item.source);
                  setOpen(true);
                }}
                key={item.key}
              >
                <TableCell>{item.source}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
