import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@heroui/react";
import { Contact, Customer } from "feathers-backend";
import { useFormik } from "formik";
import { client } from "../../utils/Client";
import { ChevronDownIcon, DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";

type ContactFormProps = {
  contact?: Contact | null;
  customer?: Customer | null;
};

export default function CustomerEditContactForm({ contact, customer }: ContactFormProps) {
  const { closeModal } = useContext(ModalContext);
  const { lang } = useContext(UserContext);
  const { contacts } = useContext(DataContext);
  const formik = useFormik({
    initialValues: contact || ({} as Contact),
    onSubmit: async (values) => {
      if (contact) {
        client.service("contacts").patch(contact._id.toString(), values);
        closeModal();
      } else {
        if (!customer) return;

        // Check if there already is a contact with the same email belonging to the customer
        const existingContact = contacts.find(
          (c) => c.email === values.email && customer.contacts?.includes(c._id.toString())
        );

        // Throw an error if a contact with the same email already exists
        if (existingContact) {
          formik.setErrors({ email: "A contact with this email already exists for this customer." });
          return;
        }

        const createdContact = await client.service("contacts").create(values);
        const customerContacts = customer.contacts || [];
        customerContacts.push(createdContact._id);
        await client.service("customers").patch(customer._id.toString(), { contacts: customerContacts });
        closeModal();
      }
    },
  });

  const deleteContact = async () => {
    if (contact) {
      await client.service("contacts").remove(contact?._id.toString());
    }
    closeModal();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2 font-semibold">{lang("Contact")}</div>
        <Input
          label="First name"
          name="firstName"
          type="text"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          label="Last name"
          name="lastName"
          type="text"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          label="Email"
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startContent={
            <EnvelopeIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Phone"
          name="phone"
          type="text"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startContent={
            <DevicePhoneMobileIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Title"
          name="title"
          type="text"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="col-span-2">
          <Textarea
            label="Notes"
            name="notes"
            type="text"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div>{formik.errors.email && <div className="my-3 text-red-500">{formik.errors.email}</div>}</div>
      <div className="flex justify-end gap-2 mt-6 mb-3">
        <Button onPress={closeModal} variant="light">
          {lang("Close")}
        </Button>
        <ButtonGroup variant="solid" color="primary">
          <Button type="submit" color="primary">
            {contact ? lang("Save") : lang("Create")}
          </Button>
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Button className="border-l border-ash " isIconOnly>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Contact options" className="max-w-[300px]">
              <DropdownItem onPress={() => formik.submitForm()} key="save">
                {lang("Save")}
              </DropdownItem>
              <DropdownItem onPress={deleteContact} key="squash">
                {lang("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ButtonGroup>
      </div>
    </form>
  );
}
