import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { ModalContext } from "../../contexts/ModalContext";
import ProspectForm from "../../components/Forms/Prospect/ProspectForm";
import { Prospect } from "feathers-backend";
import GenericTable from "../../components/Table/GenericTable";

export default function Prospects() {
  const { lang } = useContext(UserContext);
  const { prospects, users } = useContext(DataContext);
  const { openModal } = useContext(ModalContext);
  return (
    <div className="flex flex-col space-y-10">
      <div className="flex items-center justify-between">
        <h1 className="ml-2 text-2xl font-light ">{lang("Prospects")} </h1>
      </div>
      <div className="flex">
        {prospects && users && (
          <GenericTable
            data={prospects}
            filterOnTags={true}
            filterOnUser={true}
            addFunction={() => {
              openModal(lang("Add prospect"), <ProspectForm />);
            }}
            onRowClick={(item) => {
              openModal("", <ProspectForm prospect={item as Prospect} />);
            }}
            defaultSort={{ column: "createdAt", direction: "descending" }}
            columns={[
              {
                name: lang("Prospect"),
                uid: "prospect",
                sortable: false,
              },
              {
                name: lang("Title"),
                uid: "contact.title",
                sortable: true,
              },
              {
                name: lang("Email"),
                uid: "contact.email",
                sortable: true,
              },
              {
                name: lang("Phone"),
                uid: "contact.phone",
                sortable: true,
              },
              {
                name: lang("Tags"),
                uid: "tags",
                sortable: false,
              },
              {
                name: lang("Created at"),
                uid: "createdAt",
                sortable: true,
              },
              {
                name: lang("Assigned To"),
                uid: "assignedTo",
                sortable: true,
              },
            ]}
            initialVisibleColumns={[
              "prospect",
              "contact.title",
              "contact.email",
              "contact.phone",
              "tags",
              "value",
              "createdAt",
              "assignedTo",
            ]}
          />
        )}
      </div>
    </div>
  );
}
