import { Board, Contact, Customer, OpportunityData } from "feathers-backend";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { client } from "../../../utils/Client";
import { ModalContext } from "../../../contexts/ModalContext";
import { Autocomplete, AutocompleteItem, Avatar, Button, Input, Select, SelectItem, Textarea } from "@heroui/react";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { AuthContext } from "../../../contexts/AuthContext";
import SelectTags from "../Fields/SelectTags";
import { initializeOpportunityData } from "../../../helpers/initializeOpportunityData";

type NewOpportunityProps = {
  board?: Board;
  customer?: Customer;
};

export default function NewOpportunity({ board, customer }: NewOpportunityProps) {
  const { closeModal } = useContext(ModalContext);
  const { customers, contacts, users, boards } = useContext(DataContext);
  const { me } = useContext(AuthContext);

  // Local state for selections
  const [selectedCustomer, setSelectedCustomer] = useState<{} | undefined>(customer?._id);
  const [selectedContact, setSelectedContact] = useState<{} | undefined>(undefined);
  const [selectedAssignee, setSelectedAssignee] = useState<{} | undefined>(me?._id);

  // Local state for sorted contacts of selected customer
  const [sortedContacts, setSortedContacts] = useState<Contact[]>([]);

  // Compute sortedSources from your team sources:
  const sortedSources = useMemo(() => {
    const sources = me?.currentTeam?.sources || [];
    return [...sources].sort().map((s: string) => ({ key: s, label: s }));
  }, [me]);

  useEffect(() => {
    // Update Formik's state when local selections change
    formik.setFieldValue("customerId", selectedCustomer);
    formik.setFieldValue("contactId", selectedContact);
    formik.setFieldValue("assignedTo", selectedAssignee);
  }, [selectedCustomer, selectedContact, selectedAssignee]);

  const formik = useFormik<OpportunityData>({
    initialValues: {
      ...initializeOpportunityData(),
      boardId: board?._id || boards[0]?._id,
      customerId: selectedCustomer || null,
      contactId: selectedContact || null,
      assignedTo: selectedAssignee || null,
      tags: me.tags || [],
    },
    onSubmit: async (values) => {
      await client.service("opportunities").create(values);
      closeModal();
    },
  });

  // Fetch contacts for the selected customer
  useEffect(() => {
    if (selectedCustomer) {
      const selectedCustomerDetails = customers.find((customer) => customer._id.toString() === selectedCustomer);
      if (selectedCustomerDetails?.contacts) {
        const customerContacts = contacts.filter((contact) =>
          selectedCustomerDetails.contacts?.includes(contact._id.toString())
        );
        setSortedContacts(customerContacts);
      } else {
        setSortedContacts([]);
      }
    } else {
      setSortedContacts([]);
    }
  }, [selectedCustomer, customers, contacts]);

  const sortedCustomers = useMemo(() => {
    return [...(customers || [])]
      .filter((customer) => customer && customer.name)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [customers]);

  const { lang } = useContext(UserContext);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        {/* Tags */}
        <div className="col-span-2">
          <SelectTags name="tags" onChange={formik.handleChange} value={formik.values.tags} />
        </div>
        {/* Display Name */}
        <Input
          className="col-span-2"
          label={lang("Display name")}
          name="name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {/* Notes */}
        <div className="col-span-2">
          <Textarea
            label={lang("Notes")}
            name="notes"
            type="text"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        {/* Expected Value */}
        <Input
          label={lang("Expected value")}
          name="expectedValue"
          type="number"
          value={formik.values.expectedValue?.toString() || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {/* Existing Customer */}
        <Autocomplete
          defaultItems={sortedCustomers}
          label={lang("Existing customer") + "?"}
          fullWidth={true}
          placeholder="Select a customer"
          labelPlacement="inside"
          isClearable={true}
          selectedKey={formik.values.customerId?.toString()}
          onSelectionChange={(value) => setSelectedCustomer(value as any)}
        >
          {(customer) => (
            <AutocompleteItem key={customer._id.toString()} textValue={customer.name}>
              <div className="flex items-center gap-2">
                <Avatar name={customer.name} className="flex-shrink-0" size="sm" />
                <div className="flex flex-col">
                  <span className="text-small">{customer.name}</span>
                </div>
              </div>
            </AutocompleteItem>
          )}
        </Autocomplete>
        {/* Existing Contact */}
        {sortedContacts.length > 0 && (
          <Autocomplete
            defaultItems={sortedContacts}
            label={lang("Existing contact") + "?"}
            fullWidth={true}
            placeholder={lang("Select contact")}
            labelPlacement="inside"
            isClearable={true}
            shouldCloseOnBlur={true}
            selectedKey={formik.values.contactId?.toString()}
            onSelectionChange={(value) => setSelectedContact(value as any)}
          >
            {(contact) => (
              <AutocompleteItem key={contact._id.toString()} textValue={contact.firstName + " " + contact.lastName}>
                <div className="flex items-center gap-2">
                  <Avatar name={contact.firstName + " " + contact.lastName} className="flex-shrink-0" size="sm" />
                  <div className="flex flex-col">
                    <span className="text-small">{contact.firstName + " " + contact.lastName}</span>
                  </div>
                </div>
              </AutocompleteItem>
            )}
          </Autocomplete>
        )}

        {/* Board selector: if more than one board, allow selection */}
        {boards && boards.length > 1 && (
          <div className="col-span-2">
            <Autocomplete
              defaultItems={boards}
              label={lang("Select board")}
              fullWidth={true}
              placeholder={lang("Select board")}
              labelPlacement="inside"
              isClearable={false}
              selectedKey={formik.values.boardId?.toString()}
              onSelectionChange={(value) => formik.setFieldValue("boardId", value ? value.toString() : null)}
            >
              {(board) => (
                <AutocompleteItem key={board._id.toString()} textValue={board.name}>
                  <div className="flex items-center gap-2">
                    <span className="text-small">{board.name}</span>
                  </div>
                </AutocompleteItem>
              )}
            </Autocomplete>
          </div>
        )}
        <Select
          aria-label="Source"
          items={new Set(sortedSources)}
          selectionMode="single"
          // When no source is selected, use an empty set
          selectedKeys={formik.values.source ? new Set([formik.values.source]) : new Set()}
          onSelectionChange={(keys) => {
            const key = Array.from(keys)[0] || "";
            formik.setFieldValue("source", key);
          }}
          placeholder={lang("Select source")}
          label="Source"
        >
          {(item: any) => (
            <SelectItem key={item.key} textValue={item.label}>
              <div className="flex items-center gap-2">
                <span className="text-small">{item.label}</span>
              </div>
            </SelectItem>
          )}
        </Select>
        {/* Assigned To */}
        <div className="">
          <Autocomplete
            defaultItems={users}
            label={lang("Assigned to")}
            fullWidth={true}
            placeholder={lang("Select user")}
            labelPlacement="inside"
            isClearable={true}
            shouldCloseOnBlur={true}
            selectedKey={formik.values.assignedTo?.toString()}
            onSelectionChange={(value) => setSelectedAssignee(value as any)}
          >
            {(user) => (
              <AutocompleteItem key={user._id.toString()} textValue={user.fullName}>
                <div className="flex items-center gap-2">
                  <Avatar name={user.fullName} className="flex-shrink-0" size="sm" />
                  <div className="flex flex-col">
                    <span className="text-small">{user.fullName}</span>
                  </div>
                </div>
              </AutocompleteItem>
            )}
          </Autocomplete>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <Button onPress={closeModal} variant="light">
          {lang("Close")}
        </Button>
        <Button type="submit" color="primary">
          {lang("Save")}
        </Button>
      </div>
    </form>
  );
}
