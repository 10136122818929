import { useContext, useState } from "react";
import { client } from "../../../utils/Client";
import { Button } from "@heroui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as yup from "yup";
import { Customer } from "feathers-backend";
import { UserContext } from "../../../contexts/UserContext";
import { useOpportunity } from "../../../contexts/OpportunityContext";

type Props = {
  customer?: Customer;
};

export default function RexorProject({ customer }: Props) {
  const { opportunity } = useOpportunity();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { lang } = useContext(UserContext);

  // Validation schema for project creation
  const validationSchema = yup.object().shape({
    opportunityName: yup.string().required("Opportunity name is required"),
    opportunityValue: yup.number().required("Opportunity value is required"),
  });

  const validateFields = () => {
    try {
      validationSchema.validateSync(
        {
          opportunityName: opportunity.name,
          opportunityValue: opportunity.expectedValue,
        },
        { abortEarly: false }
      );
      setErrors([]);
      return true;
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors(err.errors);
      }
      return false;
    }
  };

  const handleCreateProject = async () => {
    const isValid = validateFields();
    if (!isValid) return;
    if (errors.length > 0) return;

    setIsLoading(true);
    try {
      const result = await client.service("rexor").createProject(
        {
          opportunity,
        },
        {}
      );
      if (result.status !== 200) {
        setErrors([result.detail]);
      }
    } catch (error: any) {
      setErrors(["An unexpected error occurred."]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col p-4 space-y-4 border rounded-lg border-ash">
      <div className="relative">
        <div className="w-24 mx-auto">
          {/* Replace with your project logo or relevant SVG */}
          <img className="" src="/rexor-logo.png" />
        </div>
        {opportunity.rexorUID && (
          <XMarkIcon
            onClick={() => alert("Rexor project already exists!")} // You can add logic for deletion here if needed
            className="absolute top-0 right-0 w-4 h-4 cursor-pointer text-ash hover:text-line"
          />
        )}
      </div>
      {!opportunity.rexorUID && (
        <>
          <Button
            isLoading={isLoading}
            onPress={handleCreateProject}
            className=""
            style={{ backgroundColor: "#ffd063", color: "#013a4c" }}
          >
            {lang("Create project")}
          </Button>
          {errors.length > 0 && (
            <div className="text-danger">
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}
        </>
      )}
      {opportunity.rexorUID && (
        <>
          <div className="">Rexor Project UID: {opportunity.rexorUID}</div>
          <Button
            type="button"
            onPress={() => window.open(`https://app.rexor.com/projects/${opportunity.rexorUID}`, "_blank")}
            style={{ backgroundColor: "#ffd063", color: "#013a4c" }}
          >
            Open in Rexor
          </Button>
        </>
      )}
    </div>
  );
}
