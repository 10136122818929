import { Input, Select, SelectItem, Textarea } from "@heroui/react";
import { OpportunityPatch } from "feathers-backend";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { useFormikContext } from "formik";

export default function OpportunityCustomer() {
  const { values, handleChange, handleBlur } = useFormikContext<OpportunityPatch>();
  const { lang } = useContext(UserContext);
  const billingmethods = [
    { key: "O", label: "E-faktura" },
    { key: "E", label: "Faktura via E-post (PDF)" },
    { key: "P", label: "Pappersfaktura" },
  ];
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <Input
          label={lang("Customer name")}
          name="customer.name"
          type="text"
          value={values.customer?.name ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Organization number")}
          name="customer.organizationNumber"
          type="text"
          value={values.customer?.organizationNumber ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Custom ID")}
          name="customer.customCustomerId"
          type="text"
          value={values.customer?.customCustomerId ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Website")}
          name="customer.website"
          type="text"
          value={values.customer?.website ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="col-span-2 mt-2">
          <Textarea
            label={lang("Notes")}
            name="customer.notes"
            type="text"
            value={values.customer?.notes ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-span-2 mt-2 text-sm">{lang("Billing address")}</div>
        <Input
          label={lang("Street")}
          name="customer.billingAddress.street"
          type="text"
          value={values.customer?.billingAddress?.street ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("City")}
          name="customer.billingAddress.city"
          type="text"
          value={values.customer?.billingAddress?.city ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Postal code")}
          name="customer.billingAddress.postalCode"
          type="text"
          value={values.customer?.billingAddress?.postalCode ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Country")}
          name="customer.billingAddress.country"
          type="text"
          value={values.customer?.billingAddress?.country ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Billing Email")}
          name="customer.billingAddress.billingEmail"
          type="text"
          value={values.customer?.billingAddress?.billingEmail ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Select
          label={lang("Billing Method")}
          placeholder="Välj faktureringsmetod"
          selectedKeys={[values.customer?.billingAddress?.billingMethod ?? "O"]}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {billingmethods.map((method) => (
            <SelectItem key={method.key}>{method.label}</SelectItem>
          ))}
        </Select>
        <Input
          label={lang("GLN")}
          name="customer.billingAddress.gln"
          type="text"
          value={values.customer?.billingAddress?.gln ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="col-span-2 mt-2 text-sm">{lang("Visiting address")}</div>
        <Input
          label={lang("Street")}
          name="customer.visitingAddress.street"
          type="text"
          value={values.customer?.visitingAddress?.street ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("City")}
          name="customer.visitingAddress.city"
          type="text"
          value={values.customer?.visitingAddress?.city ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Postal code")}
          name="customer.visitingAddress.postalCode"
          type="text"
          value={values.customer?.visitingAddress?.postalCode ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={lang("Country")}
          name="customer.visitingAddress.country"
          type="text"
          value={values.customer?.visitingAddress?.country ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
}
