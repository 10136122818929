import "./App.css";
import Layout from "./layout/Layout";
import { AuthProvider } from "./contexts/AuthContext";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import { UserProvider } from "./contexts/UserContext";
import { DataProvider } from "./contexts/DataContext";
import Board from "./pages/Board";
import { HeroUIProvider } from "@heroui/react";
import Settings from "./pages/Settings/Settings";
import Profile from "./pages/Settings/Profile";
import Team from "./pages/Settings/Team";
import Integrations from "./pages/Settings/Integrations";
import Export from "./pages/Settings/Export";
import Faq from "./pages/Settings/Faq";
import Prospects from "./pages/Prospects/Prospects";
import Customfields from "./pages/Settings/Customfields";
import Deals from "./pages/Deals/Deals";
import { ModalProvider } from "./contexts/ModalContext";
import Customers from "./pages/Customers/Customers";
import CustomerShow from "./pages/Customers/CustomerShow";
import CustomerDetails from "./pages/Customers/CustomerDetails";
import CustomerContacts from "./pages/Customers/CustomerContacts";
import CustomerActivities from "./pages/Customers/CustomerActivities";
import CustomerDeals from "./pages/Customers/CustomerDeals";
import Signup from "./pages/Signup";
import Tags from "./pages/Settings/Tags";
import Rexor from "./pages/Settings/Integrations/Rexor";
import Oneflow from "./pages/Settings/Integrations/Oneflow";
import Links from "./pages/Settings/Links";
import Contacts from "./pages/Contacts/Contacts";
import CustomerRexorProjects from "./pages/Customers/CustomerRexorProjects";
import Mailboxes from "./pages/Settings/Mailboxes";
import Brightcall from "./pages/Settings/Integrations/Brightcall";
import AuthLayout from "./pages/Login/AuthLayout";
import SigninForm from "./pages/Login/SigninForm";
import SignupForm from "./pages/Login/SignupForm";
import ForgotPasswordForm from "./pages/Login/ForgotPasswordForm";
import ResetPasswordForm from "./pages/Login/ResetPasswordForm";
import ProtectedRoute from "./contexts/ProtectedRoute";
import NotFound from "./pages/NotFound";
import Sources from "./pages/Settings/Sources";

function App() {
  return (
    <HeroUIProvider>
      <div>
        <Routes>
          <Route
            path="/login"
            element={
              <AuthLayout>
                <SigninForm />
              </AuthLayout>
            }
          />
          <Route
            path="/signup"
            element={
              <AuthLayout>
                <SignupForm />
              </AuthLayout>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <AuthLayout>
                <ForgotPasswordForm />
              </AuthLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AuthLayout>
                <ResetPasswordForm />
              </AuthLayout>
            }
          />
          <Route path="/signup/:id" element={<Signup />} />
          <Route
            element={
              <AuthProvider>
                <ProtectedRoute>
                  <UserProvider>
                    <DataProvider>
                      <ModalProvider>
                        <Layout />
                      </ModalProvider>
                    </DataProvider>
                  </UserProvider>
                </ProtectedRoute>
              </AuthProvider>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/prospects" element={<Prospects />} />
            <Route path="/followups" element={<h1>Follow-up</h1>} />
            <Route path="/deals" element={<Deals />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/customers/:id" element={<CustomerShow />}>
              <Route path="details" element={<CustomerDetails />} />
              <Route path="contacts" element={<CustomerContacts />} />
              <Route path="activities" element={<CustomerActivities />} />
              <Route path="deals" element={<CustomerDeals />} />
              <Route path="rexor-projects" element={<CustomerRexorProjects />} />
            </Route>
            <Route path="/board/:id" element={<Board />} />
            <Route path="/settings" element={<Settings />}>
              <Route path="profile" element={<Profile />} />
              <Route path="team" element={<Team />} />
              <Route path="customfields" element={<Customfields />} />
              <Route path="tags" element={<Tags />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="links" element={<Links />} />
              <Route path="sources" element={<Sources />} />
              <Route path="rexor" element={<Rexor />} />
              <Route path="oneflow" element={<Oneflow />} />
              <Route path="brightcall" element={<Brightcall />} />
              <Route path="export" element={<Export />} />
              <Route path="faq" element={<Faq />} />
              <Route path="mailboxes" element={<Mailboxes />} />
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </HeroUIProvider>
  );
}

export default App;
