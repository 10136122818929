import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Select, SelectItem, Selection } from "@heroui/react";
import { DataContext } from "../../../contexts/DataContext";
import { UserContext } from "../../../contexts/UserContext";
import { AuthContext } from "../../../contexts/AuthContext";
import UserAvatar from "../../UserAvatar";
import { User } from "feathers-backend";

type Props = {
  name: string;
  size?: "sm" | "md" | "lg";
  onChange: (name: string, value: string | null) => void;
  value: string | undefined;
};

export default function SelectAssignee({ name, onChange, value, size }: Props) {
  const { me } = useContext(AuthContext);
  const { users } = useContext(DataContext);
  const { lang } = useContext(UserContext);

  // Build a plain array of items from users
  const userItems = useMemo(() => {
    const validUsers = users.filter((u) => u && u._id && u.fullName);
    let sortedArray = [...validUsers].sort((a, b) => a.fullName.localeCompare(b.fullName));
    if (me && me._id && me.fullName) {
      const index = sortedArray.findIndex((u) => u._id.toString() === me._id.toString());
      if (index === -1) {
        sortedArray.unshift(me);
      } else if (index > 0) {
        const [myUser] = sortedArray.splice(index, 1);
        sortedArray.unshift(myUser);
      }
    }
    return sortedArray.map((user) => ({
      key: user._id.toString(),
      label: user.fullName,
      data: user,
    }));
  }, [users, me]);

  // Create a Set for the Select's items prop
  const itemsSet = useMemo(() => new Set(userItems), [userItems]);

  // Lookup function to get full user data by key
  const lookupUser = useCallback(
    (key: string): User | undefined => {
      return users.find((user) => user._id.toString() === key);
    },
    [users]
  );

  // Manage local selection state
  const [selectedKeys, setSelectedKeys] = useState<Selection>(() => {
    return value ? new Set([value]) : new Set();
  });

  // Update selection if the prop value changes
  useEffect(() => {
    if (value) {
      setSelectedKeys(new Set([value]));
    } else {
      setSelectedKeys(new Set());
    }
  }, [value]);

  // When selection changes, update local state and call onChange prop
  const onSelectionChange = useCallback(
    (keys: Selection) => {
      const key = Array.from(keys)[0]?.toString() || "";
      onChange(name, key || null);
      setSelectedKeys(keys);
    },
    [name, onChange]
  );

  return (
    <Select
      aria-label="Select user"
      items={itemsSet}
      size={size || "md"}
      fullWidth
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      placeholder={lang("Select user")}
      renderValue={(items: any) =>
        items.map((item: any) => (
          <div key={item.key} className="flex items-center gap-2">
            <UserAvatar user={lookupUser(item.key) as User} size="tiny" />
          </div>
        ))
      }
    >
      {userItems.map((userItem) => (
        <SelectItem key={userItem.key} textValue={userItem.label} {...({ data: userItem.data } as any)}>
          <div className="flex items-center gap-2">
            <UserAvatar user={userItem.data as User} size="tiny" />
          </div>
        </SelectItem>
      ))}
    </Select>
  );
}
