import { ReactNode, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "./AuthContext";
import Intercom from "@intercom/messenger-js-sdk";

interface JwtPayload {
  exp: number;
}

const APP_ID = "ouzcr42l"; // Replace with your Intercom workspace ID

export default function ProtectedRoute({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, isAuthenticated } = useContext(AuthContext);
  const token = localStorage.getItem("feathers-jwt");

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const expiryTime = decoded.exp * 1000;
      const now = Date.now();
      if (now >= expiryTime) {
        navigate("/login");
      } else {
        const timeout = setTimeout(() => {
          navigate("/login");
        }, expiryTime - now);
        return () => clearTimeout(timeout);
      }
    } catch (error) {
      console.error("Failed to decode token:", error);
      navigate("/login");
    }
  }, [token, navigate, location.pathname]);

  // Boot Intercom using the npm package once the user is authenticated
  useEffect(() => {
    if (isAuthenticated && me && me._id) {
      Intercom({
        app_id: APP_ID,
        user_id: me._id.toString(),
        email: me.email,
        name: me.fullName,
        user_hash: me.intercomHash,
        company: {
          company_id: me.currentTeamId,
          name: me.currentTeam.name,
        },
        created_at: me.createdAt ? Math.floor(me.createdAt / 1000) : Math.floor(Date.now() / 1000),
      });
    }
  }, [isAuthenticated, me]);

  return <>{children}</>;
}
