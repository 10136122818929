import { useContext, useEffect, useState, useMemo } from "react";
import { Select, SelectItem, SelectSection, Selection } from "@heroui/react";
import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import UserAvatar from "./UserAvatar";
import { User } from "feathers-backend";

export default function UsersFilter() {
  const { me } = useContext(AuthContext);
  const { users, setUsersFilter } = useContext(DataContext);
  const { lang } = useContext(UserContext);

  // Initialize selection from localStorage
  const [selectedKeys, setSelectedKeys] = useState<Selection>(() => {
    const stored = localStorage.getItem("selectedUser");
    return stored ? new Set([stored]) : new Set();
  });

  // Update local storage and DataContext when selection changes
  useEffect(() => {
    const singleUserId = Array.from(selectedKeys)[0];
    if (singleUserId) localStorage.setItem("selectedUser", singleUserId.toString());
    else localStorage.removeItem("selectedUser");
    setUsersFilter(selectedKeys);
  }, [selectedKeys, setUsersFilter]);

  // Map users to plain objects with key and label.
  const userItems = useMemo(() => {
    // Filter out any user that lacks _id or fullName.
    const validUsers = users.filter((user) => user && user._id && user.fullName);
    // Sort alphabetically by fullName.
    let sortedArray = [...validUsers].sort((a, b) => a.fullName.localeCompare(b.fullName));
    // If "me" exists, ensure it's at the front.
    if (me && me._id && me.fullName) {
      const index = sortedArray.findIndex((user) => user._id.toString() === me._id.toString());
      if (index === -1) {
        sortedArray.unshift(me);
      } else if (index > 0) {
        const [myUser] = sortedArray.splice(index, 1);
        sortedArray.unshift(myUser);
      }
    }
    // Map to a plain object with key and label.
    return sortedArray.map((user) => ({
      key: user._id.toString(),
      label: user.fullName,
    }));
  }, [users, me]);

  // Build a Set for the Select's items prop.
  const defaultItems = useMemo(() => new Set(userItems), [userItems]);

  // Create a lookup function to retrieve a user by key.
  const lookupUser = (key: string): User | undefined => {
    return users.find((user) => user._id.toString() === key);
  };

  // Build separate arrays for the sections.
  const meItem = useMemo(
    () => (me && me._id && me.fullName ? { key: me._id.toString(), label: me.fullName } : null),
    [me]
  );
  const otherItems = useMemo(() => {
    return userItems.filter((item) => (me ? item.key !== me._id.toString() : true));
  }, [userItems, me]);

  return (
    <Select
      aria-label="users"
      items={defaultItems}
      selectionMode="single"
      selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}
      placeholder={lang("Select user")}
      renderValue={(items: any) =>
        items.map((item: any) => (
          <div key={item.key} className="flex items-center gap-2">
            <UserAvatar user={lookupUser(item.key) as User} size="tiny" />
          </div>
        ))
      }
    >
      {meItem && (
        <SelectSection showDivider title="">
          <SelectItem key={meItem.key} textValue={meItem.label} {...({ data: lookupUser(meItem.key) } as any)}>
            <div className="flex items-center gap-2">
              <UserAvatar user={lookupUser(meItem.key) as User} size="tiny" />
            </div>
          </SelectItem>
        </SelectSection>
      )}
      <SelectSection title="">
        {otherItems.map((item) => (
          <SelectItem key={item.key} textValue={item.label} {...({ data: lookupUser(item.key) } as any)}>
            <div className="flex items-center gap-2">
              <UserAvatar user={lookupUser(item.key) as User} size="tiny" />
            </div>
          </SelectItem>
        ))}
      </SelectSection>
    </Select>
  );
}
