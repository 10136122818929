import { Button, Checkbox, Input } from "@heroui/react";
import { Formik, Form, useFormikContext } from "formik";
import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { client } from "../../../utils/Client";

interface RexorSettingsFormValues {
  username: string;
  password: string;
  clientId: string;
}

export default function Rexor() {
  const { me } = useContext(AuthContext);
  const rexorSettings = me.currentTeam?.integrations?.rexor;

  const onSubmit = async (values: RexorSettingsFormValues, { setSubmitting }: any) => {
    const team = await client.service("teams").get(me.currentTeam?._id.toString());
    const updatedIntegrations = {
      ...team.integrations, // Spread existing integrations
      rexor: values, // Your new or updated rexor values
    };
    await client.service("teams").patch(me.currentTeam?._id.toString(), {
      integrations: updatedIntegrations,
    });
    setSubmitting(false); // Always good practice to handle submission state
  };

  return (
    <div>
      <img className="w-auto h-10" src="/rexor-logo.png" />
      <div className="mt-8 space-y-4">
        <Formik
          initialValues={
            rexorSettings ||
            ({
              username: "",
              password: "",
              clientId: "",
            } as RexorSettingsFormValues)
          }
          onSubmit={onSubmit}
          enableReinitialize // This tells Formik to reset the form when initialValues changes
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="space-y-4">
                <Input
                  label="clientId"
                  value={values.clientId || ""} // Ensure value is controlled
                  onChange={(e) => setFieldValue("clientId", e.target.value)}
                />
                <Input
                  label="Username"
                  value={values.username || ""} // Ensure value is controlled
                  onChange={(e) => setFieldValue("username", e.target.value)}
                />

                <Input
                  type="password"
                  label="Password"
                  value={values.password || ""} // Ensure value is controlled
                  onChange={(e) => setFieldValue("password", e.target.value)}
                />

                <Button type="submit">Submit</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
