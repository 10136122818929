// AuthContext.tsx
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "feathers-backend";
import { client } from "../utils/Client";

export const AuthContext = createContext({
  me: {} as User,
  updateAvatar: (newAvatarPath: string) => {},
  isAuthenticated: false,
  logout: () => {},
});

export function AuthProvider(props: any) {
  const navigate = useNavigate();
  const [authMessage, setAuthMessage] = useState("");
  const [me, setMe] = useState<User>({} as User);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const reauthenticate = async () => {
      try {
        const res = await client.reAuthenticate();
        setMe(res.user);
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    // Attempt to reauthenticate on component mount
    reauthenticate();
  }, [navigate]);

  const updateAvatar = async (newAvatarPath: string) => {
    setMe((prev) => ({
      ...prev,
      avatar: `${newAvatarPath}?${Date.now()}`,
    }));
  };

  const logout = async () => {
    await client.logout();
    setIsAuthenticated(false);
    navigate("/login");
  };

  const value = {
    me,
    logout,
    updateAvatar,
    isAuthenticated,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
