import { Button, Input } from "@heroui/react";
import { useFormik } from "formik";
import { useContext, useMemo } from "react";
import { UserContext } from "../../contexts/UserContext";
import { client } from "../../utils/Client";
import { AuthContext } from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataContext";

type SourcesFormProps = {
  selectedSource: string | null;
  setOpen: (open: boolean) => void;
};

export default function SourcesForm({ selectedSource, setOpen }: SourcesFormProps) {
  const { me } = useContext(AuthContext);
  const { lang } = useContext(UserContext);
  const { teams, setTeams } = useContext(DataContext);

  // Compute the current team from the teams array (updated via setTeams)
  const currentTeam = useMemo(() => {
    if (me && me.currentTeam) {
      const found = teams.find((team) => team._id.toString() === me.currentTeam._id.toString());
      return found || me.currentTeam;
    }
    return null;
  }, [teams, me]);

  const formik = useFormik({
    initialValues: {
      source: selectedSource || "",
    },
    onSubmit: async (values) => {
      if (!currentTeam) return;
      let updatedSources: string[];
      if (selectedSource) {
        // Edit: update the matching source
        updatedSources = (currentTeam.sources || []).map((s: string) => (s === selectedSource ? values.source : s));
      } else {
        // Add new: append to the array
        updatedSources = [...(currentTeam.sources || []), values.source];
      }

      // Patch the team record with the updated sources.
      const updatedTeam = await client.service("teams").patch(currentTeam._id.toString(), { sources: updatedSources });

      // Update the teams array in DataContext.
      setTeams((prevTeams) =>
        prevTeams.map((team) => (team._id.toString() === updatedTeam._id.toString() ? updatedTeam : team))
      );

      setOpen(false);
    },
  });

  const handleDelete = async () => {
    if (!currentTeam || !selectedSource) return;
    const updatedSources = (currentTeam.sources || []).filter((s: string) => s !== selectedSource);
    const updatedTeam = await client.service("teams").patch(currentTeam._id.toString(), { sources: updatedSources });
    setTeams((prevTeams) =>
      prevTeams.map((team) => (team._id.toString() === updatedTeam._id.toString() ? updatedTeam : team))
    );
    setOpen(false);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4">
      <Input
        label="Source"
        name="source"
        value={formik.values.source}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <div className="flex justify-end gap-4">
        <Button onPress={() => setOpen(false)} variant="light">
          Cancel
        </Button>
        {selectedSource && (
          <Button onPress={handleDelete} variant="flat" color="danger">
            Delete
          </Button>
        )}
        <Button type="submit" color="primary">
          {selectedSource ? lang("Save") : lang("Create")}
        </Button>
      </div>
    </form>
  );
}
