import { Button, Input } from "@heroui/react";
import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

export default function ResetPasswordForm() {
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();

  // Yup validation schema
  const validationSchema = Yup.object({
    newPassword: Yup.string().min(8, "Password should be minimum 8 characters").required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Required"),
  });

  return (
    <div className="w-full max-w-xl m-auto space-y-10">
      <div className="space-y-1">
        <h1 className="text-4xl text-gray-800">Reset password</h1>
        <p className="text-sm text-gray-500">Enter a new password for your account</p>
      </div>
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, resetForm }) => {
          const response = await fetch(`${apiUrl}/password-reset`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Service-Method": "resetPassword",
            },
            body: JSON.stringify({
              token: token,
              newPassword: values.newPassword,
            }),
          });
          const result = await response.json();

          if (response.ok && result.success) {
            setSuccessMessage("Your password has been reset successfully.");
            setErrorMessage("");
            setTimeout(() => navigate("/login"), 1500);
          } else {
            setErrorMessage(result.message);
          }
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form className="space-y-4">
            <div className="space-y-4">
              <Input
                name="newPassword"
                label="Password"
                labelPlacement="inside"
                variant="bordered"
                type="password"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="text-sm text-danger">
                <ErrorMessage name="newPassword" />
              </div>
              <Input
                name="confirmPassword"
                label="Confirm Password"
                labelPlacement="inside"
                variant="bordered"
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="text-sm text-danger">
                <ErrorMessage name="confirmPassword" />
              </div>
              <Button type="submit" variant="solid" color="primary" className="w-full" disabled={isSubmitting}>
                Reset password
              </Button>
              {errorMessage && <div className="text-sm text-danger">{errorMessage}</div>}
              {successMessage && <div className="text-sm text-success">{successMessage}</div>}
            </div>
            <div className="text-sm">
              Remember your password?
              <Link to="/login" className="ml-1 text-sm font-medium text-primary">
                Login
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
