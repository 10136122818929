import { Avatar } from "@heroui/react";
import getColor from "../utils/GetColor";
import { User } from "feathers-backend";
import { memo } from "react";

type Props = {
  user: User;
  size?: "tiny" | "sm" | "md" | "lg";
  onlyIcon?: boolean;
};

// Moved outside the component to avoid re-creation on each render
const getInitials = (name: string = "") => {
  const parts = name.split(" ");
  const initials = parts.map((part) => part[0]).join("");
  return initials.length > 1 ? initials : "?";
};

const UserAvatar = memo(({ user, size = "sm", onlyIcon = false }: Props) => {
  if (!user) return null;

  const avatarSizeStyle = size === "tiny" ? "w-6 h-6" : "";
  const avatarSizeProp = size === "tiny" ? "sm" : size;
  const fontSize = size === "tiny" ? "10px" : "12px";

  const avatarUrl =
    user && user._id
      ? `https://storage.googleapis.com/customer-connect-public-eu/avatars/${user._id.toString()}.webp`
      : undefined;

  return (
    <div className="flex items-center gap-2">
      <Avatar
        style={{
          backgroundColor: user ? getColor(user.fullName ?? "") : "#eee",
          color: "black",
          fontWeight: 500,
        }}
        showFallback
        fallback={
          user ? (
            <span style={{ fontSize: fontSize }} className="uppercase">
              {getInitials(user.fullName)}
            </span>
          ) : (
            "?"
          )
        }
        radius="sm"
        name={user.firstName}
        src={avatarUrl}
        className={avatarSizeStyle}
        classNames={{ base: "shrink-0", icon: "" }}
        size={avatarSizeProp}
      />
      {!onlyIcon && (
        <div className="flex flex-col">
          <span className="text-small">{user.fullName}</span>
          <span className="-mt-1 text-tiny text-default-400">{user.email}</span>
        </div>
      )}
    </div>
  );
});

export default UserAvatar;
