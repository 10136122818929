import { Button, Checkbox, Input } from "@heroui/react";
import { Link } from "react-router-dom";

export default function SignupForm() {
  return (
    <div className="w-full max-w-xl m-auto space-y-10">
      <div className="space-y-1">
        <h1 className="text-4xl text-gray-800">Get Started Now</h1>
        <p className="text-sm text-gray-500">Sign up with Google, Office 365 or create an account manually</p>
      </div>
      <div>
        <div className="flex items-center justify-between space-x-4">
          <Button
            startContent={<img src="/google.svg" className="w-6 h-6" />}
            variant="bordered"
            color="default"
            className="w-full py-6"
          >
            Signup with Google
          </Button>
          <Button
            startContent={<img src="/office365.svg" className="w-6 h-6" />}
            variant="bordered"
            color="default"
            className="w-full py-6"
          >
            Signup with Office 365
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-full bg-gray-300 h-0.5 my-2"></div>
        <div className="mx-2 text-sm text-gray-500">Or</div>
        <div
          className="w-full
    bg-gray-300 h-0.5 my-2"
        ></div>
      </div>
      <div className="space-y-4">
        <Input label="Email" labelPlacement="inside" variant="bordered" />
        <Input label="Password" labelPlacement="inside" variant="bordered" type="password" />
        <Input label="Confirm Password" labelPlacement="inside" variant="bordered" type="password" />

        <Checkbox>
          <div className="text-sm">
            I agree to the <span className="underline">Terms & Policy</span>
          </div>
        </Checkbox>

        <Button variant="solid" color="primary" className="w-full">
          Create account
        </Button>
      </div>
      <div className="text-sm">
        Have an account?
        <Link to="/login" className="ml-1 text-sm font-medium text-primary">
          Login
        </Link>
      </div>
    </div>
  );
}
