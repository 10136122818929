import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import GenericTable from "../../components/Table/GenericTable";
import { DataContext } from "../../contexts/DataContext";
import OpportunityForm from "../../components/Forms/Opportunity/OpportunityForm";
import { ModalContext } from "../../contexts/ModalContext";
import { Contact, Opportunity } from "feathers-backend";
import CustomerEditContactForm from "../Customers/CustomerEditContactForm";

export default function Contacts() {
  const { lang } = useContext(UserContext);
  const { contacts } = useContext(DataContext);
  const { openModal } = useContext(ModalContext);
  return (
    <div className="flex flex-col space-y-10">
      <div className="flex items-center justify-between">
        <h1 className="ml-2 text-2xl font-light">{lang("Contacts")} </h1>
      </div>
      <div className="flex">
        {contacts.length > 0 && (
          <GenericTable
            data={contacts}
            onRowClick={(item) => {
              openModal("", <CustomerEditContactForm contact={item as Contact} />);
            }}
            defaultSort={{ column: "createdAt", direction: "descending" }}
            columns={[
              {
                name: lang("Contact"),
                uid: "contact",
                sortable: false,
              },
              {
                name: lang("Title"),
                uid: "title",
                sortable: true,
              },
              {
                name: lang("Phone"),
                uid: "phone",
                sortable: true,
              },
              {
                name: lang("Created at"),
                uid: "createdAt",
                sortable: true,
              },
            ]}
            initialVisibleColumns={["contact", "title", "phone", "createdAt"]}
          />
        )}
      </div>
    </div>
  );
}
