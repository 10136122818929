import {
  Button,
  Chip,
  Input,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import Avatar from "../../components/Avatar";
import moment from "moment";
import { DataContext } from "../../contexts/DataContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { apiUrl, client } from "../../utils/Client";
import { Formik } from "formik";
import { AuthContext } from "../../contexts/AuthContext";
import * as Yup from "yup";
import { UserContext } from "../../contexts/UserContext";
import { ModalContext } from "../../contexts/ModalContext";
import UserForm from "../../components/Forms/User/UserForm";
import GenericTable from "../../components/Table/GenericTable";
import { User } from "feathers-backend";
import SelectTags from "../../components/Forms/Fields/SelectTags";

const invitationSchema = Yup.object().shape({
  inviteEmail: Yup.string().email("Invalid email address").required("Email is required"),
  role: Yup.string().oneOf(["user", "superuser", "admin"], "Invalid role").required("Role is required"),
});

export default function Team() {
  const { users, teams } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const { me } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const [invitations, setInvitations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Update the component when users are updated
  useEffect(() => {
    if (users.length > 0 && teams.length > 0) {
      setIsLoading(false);
    }
  }, [users, teams]);

  // Fetch invitations on mount
  useEffect(() => {
    fetchInvitations();
  }, []);

  const fetchInvitations = useCallback(async () => {
    const res: any = await client.service("invitations").find();
    setInvitations(res.data);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-full">Loading...</div>
      ) : (
        <div>
          <GenericTable
            key={`${users.length}-${teams.length}`}
            data={users}
            onRowClick={(user: any) => {
              me.role === "admin" && openModal("", <UserForm user={user} />);
            }}
            defaultSort={{ column: "createdAt", direction: "descending" }}
            columns={[
              {
                name: lang("User"),
                uid: "user",
                sortable: false,
              },
              {
                name: lang("Role"),
                uid: "role",
                sortable: true,
              },
              {
                name: lang("Tags"),
                uid: "tags",
                sortable: false,
              },
              {
                name: lang("Last login"),
                uid: "lastLogin",
                sortable: false,
              },
            ]}
            initialVisibleColumns={["user", "role", "tags", "lastLogin"]}
          />
          {me.role === "admin" && (
            <div>
              {invitations.length > 0 && (
                <Table className="mt-4" aria-label="Invited">
                  <TableHeader>
                    <TableColumn className="uppercase">Invited Users</TableColumn>
                    <TableColumn className="uppercase"> </TableColumn>
                    <TableColumn className="uppercase">Status</TableColumn>
                  </TableHeader>
                  <TableBody>
                    {invitations.map((invite) => (
                      <TableRow key={invite._id.toString()}>
                        <TableCell>
                          <div className="flex mt-1">
                            <div className="my-auto ml-2 mr-4">
                              <Avatar
                                name={invite.email ?? ""}
                                status="inProgress"
                                radius="md"
                                size="md"
                                image={apiUrl + "/" + invite.avatar}
                              />
                            </div>
                            <div className="flex flex-col w-full">
                              <div className="my-auto text-white">{invite.email}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex justify-center space-x-2">
                            <Button
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "https://app.customerconnect.io/signup/" + invite._id.toString()
                                );
                              }}
                              size="sm"
                            >
                              Copy link
                            </Button>
                            <Button
                              onClick={async () => {
                                await client.service("invitations").remove(invite._id.toString());
                                fetchInvitations();
                              }}
                              size="sm"
                              color="danger"
                              variant="faded"
                            >
                              Remove
                            </Button>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Chip variant="faded" size="sm" color="primary">
                            Invited
                          </Chip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              <hr className="mx-4 border-ash" />
              <Formik
                initialValues={{
                  inviteEmail: "",
                  role: "", // Ensure this matches one of the keys in your roles
                  tags: [],
                }}
                validationSchema={invitationSchema} // Pass the schema to Formik
                onSubmit={async (values, actions) => {
                  const invitation: any = {
                    email: values.inviteEmail,
                    role: values.role,
                    tags: values.tags,
                  };
                  await client.service("invitations").create(invitation);
                  fetchInvitations();
                  actions.resetForm();
                }}
              >
                {(props) => (
                  <div className="mx-8">
                    <form onSubmit={props.handleSubmit}>
                      <div className="my-4">Invite team member</div>
                      <div className="flex justify-between space-x-4">
                        <Input
                          size="md"
                          type="email"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.inviteEmail}
                          name="inviteEmail"
                          label="Email"
                          autoComplete="off"
                        />
                        {/* Select role */}

                        <Select
                          size="md"
                          label="Role"
                          placeholder="Select role"
                          name="role"
                          value={props.values.role}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          required
                        >
                          <SelectItem key="user" value="user">
                            User
                          </SelectItem>
                          <SelectItem key="superuser" value="superuser">
                            Superuser
                          </SelectItem>
                          <SelectItem key="admin" value="admin">
                            Admin
                          </SelectItem>
                        </Select>
                        <SelectTags name="tags" value={props.values.tags} onChange={props.handleChange} />
                      </div>
                      {props.errors && (
                        <div className="my-2 text-small text-placehold" id="feedback">
                          {props.touched.inviteEmail && props.errors.inviteEmail && (
                            <div className="error">{props.errors.inviteEmail}</div>
                          )}
                          {props.touched.role && props.errors.role && <div className="error">{props.errors.role}</div>}
                        </div>
                      )}
                      <div className="flex justify-end gap-2 mt-6 mb-3">
                        <Button type="submit" color="primary">
                          Send invite
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    </>
  );
}
