import { Chip } from "@heroui/react";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

export default function Integrations() {
  const { me } = useContext(AuthContext);
  return (
    <div>
      <h1>Integrations</h1>
      <p className="text-placehold">Integrate with your favorite tools and services</p>
      <div className="grid grid-cols-2 gap-4 mt-8">
        {me.currentTeam?.integrations?.rexor && (
          <NavLink
            to={"/settings/rexor"}
            className="relative p-8 border rounded-lg cursor-pointer border-ash bg-content1 hover:bg-ash hover:bg-opacity-20"
          >
            <div className="flex">
              <img className="w-auto h-10 m-auto" src="/rexor-logo.png" />
            </div>
            <Chip className="absolute top-0 right-0 m-4 text-center text-primary status">Active</Chip>
          </NavLink>
        )}
        <NavLink
          to={"/settings/oneflow"}
          className="relative p-8 border rounded-lg cursor-pointer border-ash bg-content1 hover:bg-ash hover:bg-opacity-20"
        >
          <div className="flex">
            <img className="w-auto h-8 mx-auto" src="/oneflow-logo.svg" />
          </div>
          {me.currentTeam?.integrations?.oneflow?.enabled ? (
            <Chip className="absolute top-0 right-0 m-4 text-center text-primary status">Active</Chip>
          ) : (
            <Chip className="absolute top-0 right-0 m-4 text-center text-error status">Inactive</Chip>
          )}
        </NavLink>
        <NavLink
          to={"/settings/brightcall"}
          className="relative p-8 border rounded-lg cursor-pointer border-ash bg-content1 hover:bg-ash hover:bg-opacity-20"
        >
          <div className="flex">
            <img className="w-auto h-10 m-auto" src="/brightcall-logo.svg" />
          </div>
          {me.currentTeam?.integrations?.brightcall?.enabled ? (
            <Chip className="absolute top-0 right-0 m-4 text-center text-primary status">Active</Chip>
          ) : (
            <Chip className="absolute top-0 right-0 m-4 text-center text-error status">Inactive</Chip>
          )}
        </NavLink>
      </div>
    </div>
  );
}
