import { createContext, useContext, useState, ReactNode } from "react";
import { Opportunity } from "feathers-backend";

type OpportunityContextType = {
  opportunity: Opportunity;
  setOpportunity: (opportunity: Opportunity) => void;
};

const OpportunityContext = createContext<OpportunityContextType | undefined>(undefined);

export function OpportunityProvider({
  children,
  initialOpportunity,
}: {
  children: ReactNode;
  initialOpportunity: Opportunity;
}) {
  const [opportunity, setOpportunity] = useState<Opportunity>(initialOpportunity);

  return <OpportunityContext.Provider value={{ opportunity, setOpportunity }}>{children}</OpportunityContext.Provider>;
}

export function useOpportunity() {
  const context = useContext(OpportunityContext);
  if (!context) {
    throw new Error("useOpportunity must be used within an OpportunityProvider");
  }
  return context;
}
