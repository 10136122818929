import { User } from "feathers-backend";
import { Formik, Form } from "formik";
import { apiUrl, client } from "../../utils/Client";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Avatar, Button, Checkbox, Input } from "@heroui/react";
import { UserContext } from "../../contexts/UserContext";
import { useDropzone } from "react-dropzone";
import { DataContext } from "../../contexts/DataContext";
import { object, string, ref } from "yup";

export default function Profile() {
  const { me, updateAvatar } = useContext(AuthContext);
  const { users } = useContext(DataContext);
  const { lang } = useContext(UserContext);

  const [emailNotifications, setEmailNotifications] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // When AuthContext.me changes, set email notifications from the team user
  useEffect(() => {
    if (me?._id) {
      const settings = me.currentTeam?.users.find((teamUser: any) => teamUser.userId === me._id);
      if (settings) {
        setEmailNotifications(Boolean(settings.notifications?.email));
      }
    }
  }, [me, users]);

  // Clear the success message after 3s
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  // -- 1) Handling avatar upload (via dropzone) --
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles[0]) return;
      const file = acceptedFiles[0];
      try {
        // Send file as FormData to the server
        const token = localStorage.getItem("feathers-jwt");
        const formData = new FormData();
        formData.append("avatar", file);

        const response = await fetch(`${apiUrl}/users/${me._id}`, {
          method: "PATCH",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Error uploading avatar.");

        // updateAvatar in AuthContext to refresh the UI
        updateAvatar(data.avatar);
        setSuccessMessage(lang("Avatar updated successfully"));
      } catch (error) {
        console.error("Error uploading avatar:", error);
      }
    },
    [me._id, updateAvatar, lang]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpg", ".jpeg", ".png", ".webp"] },
    multiple: false,
  });

  // -- 2) Updating profile info (name, phone, etc) + email notifications --
  const handleProfileSave = async (values: User) => {
    // 2a) Update “notifications.email” in the team user
    const teamId = me.currentTeamId;
    const teamUsers = [...(me.currentTeam?.users || [])];
    const teamUser = teamUsers.find((t: any) => t.userId === me._id);
    if (teamId && teamUser) {
      teamUser.notifications = teamUser.notifications ?? {};
      teamUser.notifications.email = emailNotifications;
      await client.service("teams").patch(teamId.toString(), { users: teamUsers });
    }

    // 2b) Patch user’s basic fields
    await client.service("users").patch(values._id.toString(), {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      linkedIn: values.linkedIn,
      title: values.title,
    });
    setSuccessMessage(lang("Profile updated successfully"));
  };

  // -- 3) Updating password --
  const passwordSchema = object({
    password: string().required().min(8, "At least 8 characters"),
    confirmPassword: string()
      .required()
      .oneOf([ref("password")], lang("Passwords do not match")),
  });

  const handleChangePassword = async (values: { password: string; confirmPassword: string }, resetForm: () => void) => {
    if (!me?._id) return;
    await client.service("users").patch(me._id.toString(), { password: values.password });
    resetForm();
    setSuccessMessage(lang("Password changed successfully"));
  };

  // If not fully loaded, or user is missing, show nothing
  if (!me?._id) return null;

  return (
    <>
      {/* Profile Form (Formik) */}
      <Formik initialValues={me} enableReinitialize onSubmit={handleProfileSave}>
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div className="grid grid-cols-2 gap-2">
              {/* Avatar */}
              <div className="col-span-2 mb-4">
                <div className="p-1 border-2 border-dashed rounded-xl border-ash w-44 h-44" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Avatar
                    showFallback
                    fallback={values.fullName}
                    name={values.fullName}
                    radius="lg"
                    className="w-full h-full"
                    // If your GCS bucket is public, you might do `src={me.avatar}`
                    src={apiUrl + "/" + me.avatar}
                  />
                </div>
              </div>
              <Input
                label="First name"
                name="firstName"
                value={values.firstName ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                label="Last name"
                name="lastName"
                value={values.lastName ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                label="Email"
                name="email"
                type="email"
                value={values.email ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                startContent={<EnvelopeIcon className="w-4 h-4 text-default-400" />}
              />
              <Input
                label="Phone"
                name="phone"
                value={values.phone ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                startContent={<DevicePhoneMobileIcon className="w-4 h-4 text-default-400" />}
              />
              <Input
                label="Title"
                name="title"
                value={values.title ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                label="LinkedIn"
                name="linkedIn"
                value={values.linkedIn ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <p className="col-span-2">{lang("Notification settings")}</p>
              <Checkbox isSelected={emailNotifications} onValueChange={(val) => setEmailNotifications(val)}>
                {lang("Email")}
              </Checkbox>
            </div>
            {successMessage && <div className="my-2 text-success">{successMessage}</div>}

            <div className="flex justify-end gap-2 mt-6 mb-3">
              <Button type="submit" color="primary">
                {lang("Save")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {/* Change Password Form */}
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={passwordSchema}
        onSubmit={(values, actions) => {
          handleChangePassword(values, actions.resetForm);
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="my-4 text-lg font-semibold">{lang("Change password")}</div>
            <div className="flex justify-between space-x-4">
              <Input
                label="Password"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.password && <div className="my-1 text-danger">{errors.password}</div>}
            {errors.confirmPassword && <div className="my-1 text-danger">{errors.confirmPassword}</div>}

            <div className="flex justify-end gap-2 mt-6 mb-3">
              <Button type="submit" color="primary">
                {lang("Change password")}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
