import { Button, Checkbox, Input } from "@heroui/react";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export default function SigninForm() {
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    window.location.href = `${apiUrl}/oauth/google`;
  };

  const handleMicrosoftLogin = () => {
    window.location.href = `${apiUrl}/oauth/microsoft`;
  };

  return (
    <div className="w-full max-w-xl m-auto space-y-10">
      <div className="space-y-1">
        <h1 className="text-4xl text-gray-800">Login</h1>
        <p className="text-sm text-gray-500">Enter your credentials to access your account</p>
      </div>
      {/* }
      <div>
        <div className="flex items-center justify-between space-x-4">
          <Button
            startContent={<img src="/google.svg" className="w-6 h-6" />}
            variant="bordered"
            color="default"
            className="w-full py-6"
            onClick={handleGoogleLogin}
          >
            Login with Google
          </Button>
          <Button
            startContent={<img src="/office365.svg" className="w-6 h-6" />}
            variant="bordered"
            color="default"
            className="w-full py-6"
            onClick={handleMicrosoftLogin}
          >
            Login with Microsoft 365
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-full bg-gray-300 h-0.5 my-2"></div>
        <div className="mx-2 text-sm text-gray-500">Or</div>
        <div
          className="w-full
        bg-gray-300 h-0.5 my-2"
        ></div>
      </div>
      */}
      <Formik
        onSubmit={async (values, { setErrors, resetForm }) => {
          const response = await fetch(`${apiUrl}/authentication`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              strategy: "local",
              email: values.email,
              password: values.password,
            }),
          });
          const result = await response.json();

          if (response.ok) {
            // Store JWT token in local storage (or session storage)
            localStorage.setItem("feathers-jwt", result.accessToken);
            // Redirect to home page on successful login
            navigate("/");
          } else {
            setErrorMessage(result.message);
          }
        }}
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().email("Invalid email format").required("Required"),
          password: Yup.string().min(8, "Password should be minimum 8 characters").required("Required"),
        })}
      >
        {({ errors, touched, handleChange }) => (
          <Form className="space-y-4">
            <Input onChange={handleChange} name="email" label="Email" labelPlacement="inside" variant="bordered" />
            <div className="text-sm text-danger">
              <ErrorMessage name="email" />
            </div>
            <Input
              onChange={handleChange}
              name="password"
              type="password"
              label="Password"
              labelPlacement="inside"
              variant="bordered"
            />
            <div className="text-sm text-danger">
              <ErrorMessage name="password" />
            </div>
            <div className="flex items-center justify-between my-2">
              <Checkbox>
                <div className="text-sm">Remember me</div>
              </Checkbox>
              <Link to="/forgot-password" className="ml-auto text-sm font-medium text-primary">
                Forgot password?
              </Link>
            </div>
            <Button type="submit" variant="solid" color="primary" className="w-full">
              Login
            </Button>
            {errorMessage && <div className="text-sm text-danger">{errorMessage}</div>}
          </Form>
        )}
      </Formik>
      <div className="text-sm">
        Dont have an account?
        <Link to="/signup" className="ml-1 text-sm font-medium text-primary">
          Create account
        </Link>
      </div>
    </div>
  );
}
