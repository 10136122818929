import { ProspectData } from "feathers-backend";
import { initializeContactData } from "./initializeContactData";
import { initializeCustomerData } from "./initializeCustomerData";

export const initializeProspectData = (prospect?: ProspectData): ProspectData => ({
  contact: initializeContactData(prospect?.contact),
  customer: initializeCustomerData(prospect?.customer),
  source: prospect?.source || "other",
  tags: prospect?.tags || [],
  assignedTo: prospect?.assignedTo || null,
  notes: prospect?.notes || "",
  teamId: prospect?.teamId || {},
});
