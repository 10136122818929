import { Button, Input } from "@heroui/react";
import { Link } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { apiUrl } from "../../utils/Client";

interface ForgotPasswordValues {
  email: string;
}

interface ForgotPasswordStatus {
  success: boolean;
  message: string;
}

export default function ForgotPasswordForm() {
  // Define validation for the email field
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
  });

  // Handle form submission
  const handleSubmit = async (
    values: ForgotPasswordValues,
    { setSubmitting, resetForm, setStatus }: FormikHelpers<ForgotPasswordValues>
  ) => {
    const statusMessage: ForgotPasswordStatus = {
      success: true,
      message: "If an account exists, a reset link has been sent.",
    };

    try {
      const response = await fetch(`${apiUrl}/password-reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Service-Method": "forgotPassword",
        },
        body: JSON.stringify({ email: values.email }),
      });
      const data = await response.json();
      // Even if user doesn't exist, we send a success message for security.
      setStatus(statusMessage);
      // Reset the form but preserve the status message:
      resetForm({ values: { email: "" }, status: statusMessage });
    } catch (error) {
      console.error("Error sending reset link:", error);
      const errorStatus: ForgotPasswordStatus = {
        success: false,
        message: "Failed to send reset link. Please try again.",
      };
      setStatus(errorStatus);
      // Optionally, reset the form (or leave the email so user can try again)
      resetForm({ values: { email: values.email }, status: errorStatus });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full max-w-xl m-auto space-y-10">
      <div className="space-y-1">
        <h1 className="text-4xl text-gray-800">Forgot password</h1>
        <p className="text-sm text-gray-500">Enter your email to receive a link to reset your password</p>
      </div>
      <Formik initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, handleChange, values, errors, touched, status }) => (
          <Form className="space-y-4">
            <Input
              label="Email"
              name="email"
              type="email"
              labelPlacement="inside"
              variant="bordered"
              value={values.email}
              onChange={handleChange}
              onBlur={handleChange}
              errorMessage={errors.email && touched.email ? errors.email : ""}
            />
            <Button type="submit" variant="solid" color="primary" className="w-full" disabled={isSubmitting}>
              Send reset link
            </Button>
            {status && (status as ForgotPasswordStatus).message && (
              <div className={(status as ForgotPasswordStatus).success ? "text-black" : "text-danger"}>
                {(status as ForgotPasswordStatus).message}
              </div>
            )}
          </Form>
        )}
      </Formik>
      <div className="text-sm">
        Remember your password?
        <Link to="/login" className="ml-1 text-sm font-medium text-primary">
          Login
        </Link>
      </div>
    </div>
  );
}
