import { useContext, useEffect, useState } from "react";
import { client } from "../utils/Client";
import { useNavigate } from "react-router-dom";
import { Bars3Icon, BellIcon, LifebuoyIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection, Button } from "@heroui/react";
import { DataContext } from "../contexts/DataContext";
import { Team } from "feathers-backend";
import UserAvatar from "../components/UserAvatar";
import ThemeSwitcher from "../utils/ThemeSwitcher";
import { show } from "@intercom/messenger-js-sdk";

export default function TopNav({ setSidebarOpen }: any) {
  const { lang } = useContext(UserContext);
  const { me } = useContext(AuthContext);
  const { teams } = useContext(DataContext);
  const [sortedTeams, setSortedTeams] = useState<Team[]>([]);

  const navigate = useNavigate();

  const logout = async () => {
    await client.logout();
    navigate("/login");
  };

  // Sort teams by name
  useEffect(() => {
    if (teams) {
      setSortedTeams(teams.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [teams]);

  const changeTeam = async (teamId: string) => {
    const result = await client.service("users").patch(me._id.toString(), { currentTeamId: teamId });
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="sticky top-0 z-40 flex items-center h-16 px-4 shadow-sm shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8 bg-offblack">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="w-6 h-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="w-px h-6 bg-gray-900/10 lg:hidden" aria-hidden="true" />

      <div className="flex justify-end flex-1 gap-x-4 lg:gap-x-6">
        <div className="flex items-center ">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 hidden">
            <span className="sr-only">View notifications</span>
            <BellIcon className="w-6 h-6" aria-hidden="true" />
          </button>
          <button type="button" className="-m-2.5 p-2.5 rounded-lg hover:bg-background " onClick={() => show()}>
            <span className="sr-only">View help</span>
            <LifebuoyIcon className="w-6 h-6 text-primary" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
          {/*<ThemeSwitcher />*/}
          <Dropdown className="">
            <DropdownTrigger>
              <Button style={{ backgroundColor: "transparent", marginBlockStart: 0 }} className="flex gap-2">
                <div className="my-auto">{me && me._id && <UserAvatar onlyIcon={true} user={me} size="md" />}</div>
                <div className="my-auto text-left text-small">
                  <div className="text-offwhite">{me?.fullName}</div>
                  <div className="text-xs text-placehold">{me?.currentTeam?.name}</div>
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" variant="flat">
              <DropdownSection aria-label="Help & Feedback">
                <DropdownItem key={"profile"} onPress={() => navigate("/settings/profile")} textValue="profile">
                  {lang("Profile")}
                </DropdownItem>
                <DropdownItem key={"teamSettings"} onPress={() => navigate("settings/team")} textValue="team">
                  {lang("Team settings")}
                </DropdownItem>
              </DropdownSection>
              <DropdownSection aria-label="Select team">
                {sortedTeams?.map((team: Team) => (
                  <DropdownItem
                    key={team._id.toString()}
                    textValue="team"
                    onPress={() => changeTeam(team._id.toString())}
                    className="text-placehold data-[hover=true]:text-line"
                  >
                    {team.name}
                  </DropdownItem>
                ))}
              </DropdownSection>
              <DropdownSection aria-label="Help & Feedback">
                <DropdownItem key="help_and_feedback">Help & Feedback</DropdownItem>
                <DropdownItem key="delete" variant="flat" color="danger" onPress={() => logout()}>
                  {lang("Log out")}
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
