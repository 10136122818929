import { BellSnoozeIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar as HeroAvatar, Badge } from "@heroui/react";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

type AvatarProps = {
  status?: string;
  image?: string;
  size?: "sm" | "md" | "lg";
  radius?: "sm" | "md" | "lg" | "none" | "full";
  name: string;
  isBordered?: boolean;
};

type ColorType = "primary" | "warning" | "success" | "danger" | "default" | "secondary" | undefined;
type PlacementType = "top-right" | "bottom-right" | "top-left" | "bottom-left" | undefined;

type StatusConfig = {
  color: ColorType;
  badgeContent?: JSX.Element;
  badgeColor?: ColorType;
  placement?: PlacementType;
  isBordered?: boolean;
};

export default function Avatar({ status = "inProgress", image, size = "md", radius = "md", name }: AvatarProps) {
  const { lang } = useContext(UserContext);

  const statusMap: Record<string, StatusConfig> = {
    unassigned: {
      color: "primary",
      isBordered: true,
      placement: "bottom-right",
    },
    followup: {
      color: "warning",
      isBordered: true,
      badgeColor: "warning",
      badgeContent: <BellSnoozeIcon className="w-3 h-3" />,
      placement: "bottom-right",
    },
    closedWon: {
      color: "success",
      isBordered: true,
      badgeColor: "success",
      badgeContent: <CheckIcon className="w-3 h-3" />,
      placement: "bottom-right",
    },
    closedLost: {
      color: "danger",
      isBordered: true,
      badgeColor: "danger",
      badgeContent: <XMarkIcon className="w-3 h-3 text-pink-950" />,
      placement: "bottom-right",
    },
    new: {
      color: "primary",
      isBordered: true,
      badgeColor: "primary",
      badgeContent: <div className="text-xs">{lang("New")}</div>,
      placement: "top-left",
    },
    inProgress: {
      color: "default",
      isBordered: false,
      placement: "bottom-right",
    },
    default: {
      color: "default",
      isBordered: true,
      placement: "bottom-right",
    },
  };

  const { color, badgeContent, badgeColor, placement, isBordered } = statusMap[status] ?? statusMap.default;

  const avatarEl = (
    <HeroAvatar
      imgProps={{ loading: "eager" }}
      showFallback
      name={name}
      color={color}
      className="text-white bg-card"
      radius={radius}
      isBordered={isBordered}
      size={size}
      src={image}
    />
  );

  return badgeContent ? (
    <Badge
      isOneChar={status !== "new"} // show badge as a circle if not "New"
      content={badgeContent}
      color={badgeColor}
      placement={placement}
    >
      {avatarEl}
    </Badge>
  ) : (
    avatarEl
  );
}
