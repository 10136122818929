import { Button, Input, Select, SelectItem } from "@heroui/react";
import { useFormik } from "formik";
import { client } from "../../utils/Client";
import { Tag, TagData, TagPatch } from "feathers-backend";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";
import { initializeTagData } from "../../helpers/initializeTagData";

type TagFormProps = {
  tag?: Tag | null;
  setOpen: (open: boolean) => void;
};

const Colors = [
  "#106BA5",
  "#1CAECA",
  "#37CCE3",
  "#55D486",
  "#5DAB4A",
  "#5EA5CD",
  "#72EBAB",
  "#7DC770",
  "#8DBDD8",
  "#92DFEA",
  "#A76FBF",
  "#B5F0D1",
  "#B8DCB1",
  "#CC8FE3",
  "#CD5242",
  "#DEC1EA",
  "#E36BAE",
  "#E5912C",
  "#E5C42F",
  "#ED7667",
  "#EEB3AC",
  "#F4DB40",
  "#F4E997",
  "#F8C3E3",
  "#F9D19F",
  "#FD90D3",
  "#FDAE4B",
];

export default function TagForm({ tag, setOpen }: TagFormProps) {
  const { lang } = useContext(UserContext);

  const formik = useFormik({
    initialValues: initializeTagData(tag || undefined),
    onSubmit: async (values) => {
      // Remove undefined values to match Feathers API expectations
      if (tag) {
        await client.service("tags").patch(tag._id.toString(), values);
      } else {
        await client.service("tags").create(values);
      }
      setOpen(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="space-y-4">
        <div className="grid gap-4 lg:grid-cols-2">
          <Input
            isRequired
            label="Tag label"
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Select
            isRequired
            name="color"
            label="Tag color"
            selectedKeys={[formik.values.color]}
            onChange={(event) => formik.setFieldValue("color", event.target.value)}
          >
            {Colors.map((color) => (
              <SelectItem key={color} value={color}>
                <div style={{ color }}>{color}</div>
              </SelectItem>
            ))}
          </Select>
        </div>

        <div className="flex justify-end gap-4">
          {tag && (
            <Button
              onPress={async () => {
                await client.service("tags").remove(tag._id.toString());
                setOpen(false);
              }}
              color="danger"
            >
              {lang("Delete")}
            </Button>
          )}
          <Button type="submit" color="primary">
            {tag ? lang("Save") : lang("Create")}
          </Button>
        </div>
      </div>
    </form>
  );
}
