import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button } from "@heroui/react";
import { useContext, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { DataContext } from "../../contexts/DataContext";
import { AuthContext } from "../../contexts/AuthContext";
import { client } from "../../utils/Client";
import { UserContext } from "../../contexts/UserContext";

export default function Mailboxes() {
  const { mailboxes } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [selectedMailbox, setSelectedMailbox] = useState<any>(null);
  const { lang } = useContext(UserContext);

  const getTitle = () => {
    if (!open) return "Mailboxes";
    if (open && !selectedMailbox) return "Add a New Mailbox";
    if (open && selectedMailbox) return "Edit Mailbox";
  };

  const initiateOAuth = async (provider: string) => {
    try {
      const response = await client.service("mailboxes").init({ provider }, { authenticated: true });
      if (response.redirect) {
        window.location.href = response.redirect;
      }
    } catch (error) {
      console.error("Error initiating OAuth:", error);
    }
  };

  const columns = [
    { label: "Email", key: "email" },
    { label: "Provider", key: "provider" },
    { label: "ID", key: "_id" },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h1>{getTitle()}</h1>
        <div className="flex space-x-4">
          <Button
            startContent={<img className="w-6 h-6" src="/gmail.png" />}
            onPress={() => initiateOAuth("gmail")}
            color="default"
            variant="ghost"
            className="w-full py-6"
          >
            {lang("Add")} Gmail
          </Button>
          <Button
            startContent={<img className="w-6 h-6" src="/office365.svg" />}
            onPress={() => initiateOAuth("office365")}
            color="default"
            variant="ghost"
            className="w-full py-6"
          >
            {lang("Add")} Office 365
          </Button>
        </div>
      </div>

      <Table
        classNames={{
          wrapper: "px-0 shadow-none",
          tr: "hover:bg-ash cursor-pointer",
        }}
        aria-label="Mailboxes Table"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent="No mailboxes added" items={mailboxes || []}>
          {(item) => (
            <TableRow
              onClick={() => {
                setSelectedMailbox(item);
                setOpen(true);
              }}
              key={item._id.toString()}
            >
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.provider}</TableCell>
              <TableCell>
                <div className="text-sm text-label">{item._id.toString()}</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
