import { Opportunity, OpportunityPatch } from "feathers-backend";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { client } from "../../../utils/Client";
import { ModalContext } from "../../../contexts/ModalContext";
import { Button } from "@heroui/react";
import { UserContext } from "../../../contexts/UserContext";
import { initializeOpportunityData } from "../../../helpers/initializeOpportunityData";

type Props = {
  opportunity: Opportunity;
  handleChange?: (e: any) => void;
  handleBlur?: (e: any) => void;
};

export default function OpportunityFollowup({ opportunity, handleBlur, handleChange }: Props) {
  const { lang } = useContext(UserContext);
  const [followupDate, setFollowupDate] = useState<Date>(new Date());
  const { closeModal } = useContext(ModalContext);

  const formik = useFormik({
    initialValues: initializeOpportunityData(opportunity),
    onSubmit: async () => {
      if (opportunity) {
        client.service("opportunities").patch(opportunity._id.toString(), {
          followUpDate: followupDate.getTime(),
          status: "followup",
        });
        closeModal();
      }
    },
  });

  const unsetFollowUp = () => {
    if (opportunity) {
      client.service("opportunities").patch(opportunity._id.toString(), {
        $unset: { followUpDate: true },
        status: "inProgress",
      } as OpportunityPatch);
      closeModal();
    }
  };

  const addDaysToDate = (days: number) => {
    const date = new Date(followupDate);
    date.setDate(date.getDate() + days);
    return date;
  };

  const addMonthsToDate = (months: number) => {
    const date = new Date(followupDate);
    date.setMonth(date.getMonth() + months);
    return date;
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [year, month, day] = e.target.value.split("-").map((num) => parseInt(num));
    const newDate = new Date(followupDate);
    newDate.setFullYear(year, month - 1, day);
    setFollowupDate(newDate);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.target.value.split(":").map((num) => parseInt(num));
    const newDate = new Date(followupDate);
    newDate.setHours(hours, minutes);
    setFollowupDate(newDate);
  };

  return (
    <div className="">
      <div className="flex space-x-4">
        <button className="px-2 py-1 rounded-lg bg-default-100" onClick={() => setFollowupDate(addDaysToDate(1))}>
          +1 {lang("days")}
        </button>
        <button className="px-2 py-1 rounded-lg bg-default-100" onClick={() => setFollowupDate(addDaysToDate(7))}>
          +7 {lang("days")}
        </button>
        <button className="px-2 py-1 rounded-lg bg-default-100" onClick={() => setFollowupDate(addDaysToDate(14))}>
          +14 {lang("days")}
        </button>
        <button className="px-2 py-1 rounded-lg bg-default-100" onClick={() => setFollowupDate(addMonthsToDate(1))}>
          +1 {lang("month")}
        </button>
        <button className="px-2 py-1 rounded-lg bg-default-100" onClick={() => setFollowupDate(addMonthsToDate(3))}>
          +3 {lang("months")}
        </button>
      </div>
      <div className="mt-4">{lang("Selected date")}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex space-x-4">
          <input
            className="p-2 rounded-lg bg-ash"
            type="date"
            onChange={handleDateChange}
            value={followupDate.toISOString().split("T")[0]}
          />
          <input
            className="p-2 rounded-lg bg-ash"
            type="time"
            onChange={handleTimeChange}
            value={followupDate.toTimeString().substr(0, 5)}
          />
        </div>
        <div className="flex justify-end gap-2 my-4">
          <Button size="sm" onPress={unsetFollowUp} variant="light">
            {lang("Remove follow-up")}
          </Button>
          <Button size="sm" onPress={closeModal} variant="light">
            {lang("Close")}
          </Button>
          <Button size="sm" type="submit" color="primary">
            {lang("Save")}
          </Button>
        </div>
      </form>
    </div>
  );
}
