import { OpportunityPatch } from "feathers-backend";
import Avatar from "../../Avatar";
import Formatter from "../../../utils/Formatter";
import SelectTags from "../Fields/SelectTags";
import moment from "moment";
import { UserContext } from "../../../contexts/UserContext";
import { useContext } from "react";
import { useOpportunity } from "../../../contexts/OpportunityContext";
import { useFormikContext } from "formik";

export default function ViewOpportunity() {
  const { lang } = useContext(UserContext);
  const { opportunity } = useOpportunity();
  const { handleChange } = useFormikContext<OpportunityPatch>();
  return (
    <div className="">
      <div className="-mt-2">
        <SelectTags name="tags" onChange={handleChange} useDefaultTags={false} value={opportunity.tags} />
      </div>
      <div className="flex col-span-2 my-4 ml-2">
        <div className="mt-2 mr-6 ">
          <Avatar
            name={opportunity.displayName ?? ""}
            status={opportunity.status as any}
            radius="md"
            size="lg"
            image={opportunity.avatar}
          />
        </div>
        <div className="">
          <div className="text-2xl text-offwhite">{opportunity.displayName}</div>
          {opportunity.customer?.name && <div className="-mt-1 text-placehold">{opportunity.customer.name}</div>}
          <div className="text-sm text-placehold">{opportunity.contact?.email}</div>
          <div className="text-sm text-placehold">{opportunity.contact?.phone}</div>
          {opportunity.expectedValue && opportunity.expectedValue != 0 && (
            <div className="mt-1 text-sm text-white ">{Formatter(opportunity.expectedValue)}</div>
          )}
          <div className="text-sm text-placehold">
            {lang("Created at") + ": " + moment(opportunity.createdAt).format("YYYY-MM-DD hh:mm:ss")}
          </div>
        </div>
      </div>
    </div>
  );
}
