import { Select, Selection, SelectItem } from "@heroui/react";
import { useContext, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { useFormikContext } from "formik";

type BillingMethodsProps = {
  name: string;
  value: string | {} | undefined | null;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
};

export default function BillingMethods({ name, onChange, onBlur, value }: BillingMethodsProps) {
  const { lang } = useContext(UserContext);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(value ? [String(value)] : []));

  const types = [
    { key: "e-invoice", label: lang("E-faktura") },
    { key: "email", label: lang("Faktura via E-post") },
    { key: "post", label: lang("Pappersfaktura") },
  ];

  const handleChange = (keys: Selection) => {
    setSelectedKeys(keys);
    const selectedValue = Array.from(keys).pop() as string | null;
    onChange({ target: { name, value: selectedValue ?? null } } as React.ChangeEvent<any>);
  };

  return (
    <div className="flex flex-col w-full gap-2">
      <Select
        label={lang("Faktureringssätt")}
        onSelectionChange={handleChange}
        onBlur={onBlur}
        placeholder={lang("Faktureringssätt")}
        selectedKeys={selectedKeys}
        selectionMode="single"
        items={types}
      >
        {(item) => <SelectItem key={item.key}>{item.label}</SelectItem>}
      </Select>
    </div>
  );
}
