import { User } from "feathers-backend";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { client } from "../../../utils/Client";
import { Button, Checkbox, Select, SelectItem } from "@heroui/react";
import { ModalContext } from "../../../contexts/ModalContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { UserContext } from "../../../contexts/UserContext";
import SelectTags from "../Fields/SelectTags";

type Props = {
  user: User;
};

export default function UserForm({ user }: Props) {
  const [teamUser, setTeamUser] = useState<any>();
  const { closeModal } = useContext(ModalContext);
  const { lang } = useContext(UserContext);
  const { me } = useContext(AuthContext);

  useEffect(() => {
    const team = me.currentTeam;
    const teamUser = team.users.find((teamUser: any) => teamUser.userId === user._id.toString());
    setTeamUser(teamUser);
  }, []);

  const deleteUserFromTeam = async () => {
    const team = me.currentTeam;
    const teamUsers = team.users;
    // remove the user from the team.users by users.userId
    const updatedTeamUsers = teamUsers.filter((teamUser: any) => teamUser.userId !== user._id);
    await client.service("teams").patch(team._id.toString(), { users: updatedTeamUsers });
    closeModal();
  };

  const handleSubmit = async (values: any) => {
    console.log(values);
    const team = me.currentTeam;
    const teamUser = team.users.find((teamUser: any) => teamUser.userId === user._id);
    if (teamUser) {
      teamUser.role = values.role;
      teamUser.notifications = values.notifications;
      teamUser.tags = values.tags;
      await client.service("teams").patch(team._id.toString(), { users: team.users });
    }
    closeModal();
  };

  return (
    <>
      {teamUser && (
        <Formik initialValues={teamUser} onSubmit={handleSubmit}>
          {({ values, setFieldValue, handleChange, handleBlur, dirty }) => (
            <Form>
              <div className="space-y-10">
                <Select
                  size="md"
                  label="Role"
                  placeholder="Select role"
                  name="role"
                  defaultSelectedKeys={[user.role]}
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                >
                  <SelectItem key="user" value="user">
                    User
                  </SelectItem>
                  <SelectItem key="superuser" value="superuser">
                    Superuser
                  </SelectItem>
                  <SelectItem key="admin" value="admin">
                    Admin
                  </SelectItem>
                </Select>
                <SelectTags
                  useDefaultTags={false}
                  name="tags" // The field name in your Formik form
                  onChange={handleChange}
                  value={values.tags}
                />
                <div className="space-y-2">
                  <div>{lang("Notifications")}</div>
                  <Checkbox name="notifications.email" onChange={handleChange} isSelected={values.notifications?.email}>
                    <div>{lang("Email")}</div>
                  </Checkbox>
                </div>
                <div className="flex justify-end mt-5">
                  {dirty && (
                    <Button type="submit" color="primary">
                      <div>{lang("Save")}</div>
                    </Button>
                  )}
                  <Button type="button" onPress={closeModal} className="ml-2">
                    <div>{lang("Close")}</div>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
