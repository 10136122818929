import { Opportunity, OpportunityData } from "feathers-backend";
import { initializeContactData } from "./initializeContactData";
import { initializeCustomerData } from "./initializeCustomerData";

export const initializeOpportunityData = (opportunity?: Opportunity): OpportunityData => ({
  // Basic fields
  name: opportunity?.name || "",
  expectedValue: opportunity?.expectedValue || 0,
  closedDate: opportunity?.closedDate || 0,
  closedBy: opportunity?.closedBy || undefined,
  lostReason: opportunity?.lostReason || "",
  followUpDate: opportunity?.followUpDate || undefined,
  status: opportunity?.status || "new",
  conversionMethod: opportunity?.conversionMethod || "",

  // Source / Channel / Referrer / Notes
  source: opportunity?.source || "other",
  channel: opportunity?.channel || "",
  referrer: opportunity?.referrer || "",
  notes: opportunity?.notes || "",

  // Assigned user and message
  assignedTo: opportunity?.assignedTo || null,
  message: opportunity?.message || "",

  // Contact and Customer: if available, initialize using their respective helper;
  // otherwise, create an empty object.
  contactId: opportunity?.contactId || null,
  contact: opportunity?.contact || initializeContactData(),
  customerId: opportunity?.customerId || null,
  customer: opportunity?.customer || initializeCustomerData(),

  // Board and team
  boardId: opportunity?.boardId || null,
  teamId: opportunity?.teamId || {},

  // Additional fields
  meta: opportunity?.meta || {},
  tags: opportunity?.tags || [],
  fromUrl: opportunity?.fromUrl || "",
  startUrl: opportunity?.startUrl || "",
  medium: opportunity?.medium || "",
  campaign: opportunity?.campaign || "",
  displayName: opportunity?.displayName || "",
  rexorUID: opportunity?.rexorUID || "",
  rexorId: opportunity?.rexorId || "",
  rexorParentId: opportunity?.rexorParentId || "",
  oneflow: opportunity?.oneflow || undefined,
  brightcall: opportunity?.brightcall || undefined,
  files: opportunity?.files || [],
});
