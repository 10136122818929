import { Button, Input, Select, SelectItem, Textarea } from "@heroui/react";
import { useFormik } from "formik";
import { client } from "../../utils/Client";
import { Customfield } from "feathers-backend";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { initializeCustomfieldData } from "../../helpers/initializeCustomfieldData";

type CustomfieldFormProps = {
  customfield?: Customfield | null;
  setOpen: any;
};

export default function CustomfieldForm({ customfield, setOpen }: CustomfieldFormProps) {
  const { lang } = useContext(UserContext);

  const handleAddOption = () => {
    const options = formik.values.options || [];
    options.push({ label: "", value: "" });
    formik.setFieldValue("options", options);
  };

  const handleRemoveOption = (index: number) => {
    const options = formik.values.options || [];
    options.splice(index, 1);
    formik.setFieldValue("options", options);
  };

  const handleChangeOption = (index: number, field: string, value: string) => {
    const options: any = formik.values.options || [];
    options[index][field] = value;
    formik.setFieldValue("options", options);
  };

  const formik = useFormik({
    initialValues: initializeCustomfieldData(customfield || undefined),
    onSubmit: async (values) => {
      if (customfield) {
        client.service("customfields").patch(customfield._id.toString(), values);
      } else {
        client.service("customfields").create(values as Customfield);
      }
      setOpen(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="space-y-4 ">
        <div className="grid gap-4 lg:grid-cols-2">
          <Input
            isRequired={true}
            label="Field name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Select
            isRequired={true}
            name="type"
            label="Field type"
            value={formik.values.type}
            defaultSelectedKeys={[formik.values.type || "text"]}
            onChange={(e) => {
              formik.setFieldValue("type", e.target.value);
              if (e.target.value !== "dropdown" && e.target.value !== "radio") {
                formik.setFieldValue("options", []); // Clear options if not relevant
              }
            }}
            onBlur={formik.handleBlur}
          >
            <SelectItem key="text" value="text">
              {lang("Text")}
            </SelectItem>
            <SelectItem key="textarea" value="textarea">
              {lang("Textarea")}
            </SelectItem>
            <SelectItem key="checkbox" value="checkbox">
              {lang("Checkbox")}
            </SelectItem>
            <SelectItem key="dropdown" value="dropdown">
              {lang("Dropdown")}
            </SelectItem>
            <SelectItem key="radio" value="radio">
              {lang("Radio")}
            </SelectItem>
          </Select>
          <Textarea
            label="Field description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          {["dropdown", "radio"].includes(formik.values.type) && (
            <>
              <Button onClick={handleAddOption}>{lang("Add Option")}</Button>
              {formik.values.options &&
                formik.values.options.map((option, index) => (
                  <div key={index} className="flex gap-4 my-4">
                    <Input
                      size="sm"
                      label="Option Label"
                      name={`options[${index}].label`}
                      value={option.label}
                      onChange={(e) => handleChangeOption(index, "label", e.target.value)}
                    />
                    <Input
                      size="sm"
                      label="Option Value"
                      name={`options[${index}].value`}
                      value={option.value}
                      onChange={(e) => handleChangeOption(index, "value", e.target.value)}
                    />
                    <Button
                      className="my-auto"
                      size="sm"
                      variant="flat"
                      onClick={() => handleRemoveOption(index)}
                      color="danger"
                    >
                      {lang("Delete")}
                    </Button>
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="flex justify-end gap-4">
          {customfield && (
            <Button
              size="sm"
              variant="ghost"
              onPress={() => {
                client.service("customfields").remove(customfield._id.toString());
                setOpen(false);
              }}
              color="danger"
            >
              {lang("Delete")}
            </Button>
          )}
          <Button type="submit" color="primary">
            {customfield ? lang("Save") : lang("Create")}
          </Button>
        </div>
      </div>
    </form>
  );
}
