import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../contexts/DataContext";
import KanbanBoard from "../components/Kanban/KanbanBoard";
import ListBoard from "../components/ListBoard";
import { Board as BoardType } from "feathers-backend";

export default function Board() {
  const params = useParams();
  const { boards } = useContext(DataContext);
  const [board, setBoard] = useState<BoardType>();

  useEffect(() => {
    const foundBoard = boards.find((board) => board._id === params.id);
    setBoard(foundBoard);
  }, [boards, params.id]);
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-10">
        <h1 className="ml-2 text-2xl font-light">{board?.name}</h1>
      </div>

      <div className="flex">
        {board && board.type === "kanban" && <KanbanBoard board={board} />}
        {board && board.type === "list" && <ListBoard setBoard={setBoard} board={board} />}
      </div>
    </div>
  );
}
