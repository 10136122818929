import { createClient } from "feathers-backend";
import io from "socket.io-client";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";

declare global {
  interface Window {
    client: any;
  }
}

// if env API_URL is set, use it, otherwise use localhost
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";

const connection: any = socketio(io(apiUrl));
export const client = createClient(connection).configure(authentication({ storage: window.localStorage }));

window.client = client;

// Attach a global error hook to catch 401 errors.
client.hooks({
  error: {
    all: [
      async (context) => {
        if (context.error && context.error.code === 401) {
          // Clear the token and redirect to login
          window.localStorage.removeItem("feathers-jwt");
          window.location.href = "/login";
        }
        return context;
      },
    ],
  },
});
