import { useContext, useMemo } from "react";
import { Button, Input, Select, SelectItem, Textarea } from "@heroui/react";
import { UserContext } from "../../../contexts/UserContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { useOpportunity } from "../../../contexts/OpportunityContext";
import { useFormikContext } from "formik";
import { client } from "../../../utils/Client";
import { OpportunityPatch } from "feathers-backend";

export default function OpportunityDetails() {
  const { opportunity } = useOpportunity();
  const { lang } = useContext(UserContext);
  const { me } = useContext(AuthContext);
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext<OpportunityPatch>(); // 👈 Type the form values correctly

  // Compute sortedSources from your team sources:
  const sortedSources = useMemo(() => {
    const sources = me?.currentTeam?.sources || [];
    return [...sources].sort().map((s: string) => ({ key: s, label: s }));
  }, [me]);

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="col-span-2 mt-4 text-small">{lang("Details")}</div>
      <Input
        className="col-span-2"
        label={lang("Display name")}
        name="name"
        type="text"
        value={values.name || ""}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {opportunity.message && opportunity.message !== "" && (
        <div className="col-span-2">
          <Textarea
            disabled
            label={lang("Message")}
            name="message"
            type="text"
            value={values.message || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      )}
      <div className="col-span-2">
        <Textarea
          label={lang("Notes")}
          name="notes"
          type="text"
          value={values.notes || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {opportunity.status === "closedWon" && (
        <Input
          label={lang("Value")}
          name="value"
          type="number"
          value={values.value?.toString() || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {opportunity.status !== "closedWon" && (
        <Input
          label={lang("Expected value")}
          name="expectedValue"
          type="number"
          value={values.expectedValue?.toString() || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      <Select
        aria-label="Source"
        items={new Set(sortedSources)}
        selectionMode="single"
        // When no source is selected, use an empty set
        selectedKeys={values.source ? new Set([values.source]) : new Set()}
        onSelectionChange={(keys: any) => {
          const key = keys.currentKey || "";
          setFieldValue("source", key);
        }}
        onBlur={handleBlur}
        placeholder={lang("Select source")}
        label={lang("Source")}
      >
        {(item: any) => (
          <SelectItem key={item.key} textValue={item.label}>
            <div className="flex items-center gap-2">
              <span className="text-small">{item.label}</span>
            </div>
          </SelectItem>
        )}
      </Select>
      {me?.currentTeam?.integrations?.rexor && (
        <>
          <div className="col-span-2 mt-4 text-small">{lang("Rexor")}</div>
          <div className="col-span-2 space-y-2">
            <Input
              label={lang("Projektnummer")}
              name="rexorId"
              type="text"
              size="sm"
              value={values.rexorId || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              label={lang("Överordnat projektnummer")}
              name="rexorParentId"
              type="text"
              size="sm"
              value={values.rexorParentId || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </>
      )}
      {(opportunity.status === "closedLost" || opportunity.status === "closedWon") && me.role === "admin" && (
        <div className="col-span-2 mt-5">
          <Button color="danger" onPress={() => client.service("opportunities").remove(opportunity._id.toString())}>
            {lang("Delete")}
          </Button>
        </div>
      )}
    </div>
  );
}
