import { Board, Opportunity, OpportunityPatch } from "feathers-backend";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { Input, Textarea, Checkbox, Button, Select, SelectItem, RadioGroup, Radio } from "@heroui/react";
import { UserContext } from "../../../contexts/UserContext";
import { AuthContext } from "../../../contexts/AuthContext";
import Oneflow from "../Fields/Oneflow";
import RexorProject from "../Fields/RexorProject";
import { useFormikContext } from "formik";
import { useOpportunity } from "../../../contexts/OpportunityContext";

type OpportunityProcessProps = {
  board: Board;
};

type CustomField = {
  _id: string;
  type: "text" | "textarea" | "checkbox" | "dropdown" | "radio";
  name: string;
  options?: { label: string; value: string }[];
  // ... any other properties that the custom field may have
};

export default function OpportunityProcess({ board }: OpportunityProcessProps) {
  const { opportunity } = useOpportunity();
  const { lang } = useContext(UserContext);
  const { me } = useContext(AuthContext);
  const { values, handleChange, handleBlur } = useFormikContext<OpportunityPatch>();
  const { columns, customfields } = useContext(DataContext);
  const [currentColumn, setCurrentColumn] = useState<any>(null);
  const [fields, setFields] = useState<any[]>([]);
  const [showAllFields, setShowAllFields] = useState<boolean>(() => {
    // Initialize state from local storage
    const storedValue = localStorage.getItem("showAllFields");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  // useEffect to save showAllFields to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("showAllFields", JSON.stringify(showAllFields));
  }, [showAllFields]);

  useEffect(() => fetchSortedFields(board, columns, customfields), [board, customfields]);

  const fetchSortedFields = (board: Board, columns: any[], customfields: any[]) => {
    const boardColumns = columns.filter((col) => col.boardId === board._id);
    const sortedFieldsByColumn = boardColumns.map((col) => ({
      _id: col._id.toString(),
      columnName: col.name,
      fields: col.customfields.map((fieldId: any) => customfields.find((cf) => cf._id === fieldId)).filter(Boolean), // This filters out any undefined fields
    }));
    setFields(sortedFieldsByColumn);
  };

  // useEffect to get the current column set based on if the opportunity._id is in the column.items[{_id}] array
  useEffect(() => {
    const currentColumn = columns?.find((col) =>
      col?.items?.some((item) => item?._id?.toString() === opportunity?._id?.toString())
    );
    setCurrentColumn(currentColumn);
  }, [columns, opportunity]);

  const renderTextField = (field: CustomField) => {
    // Check if there is a value for the field in the opportunity.meta object
    try {
      var value = values.meta![field._id].toString();
    } catch (error) {
      value = "";
    }
    return (
      <Input
        key={field._id}
        name={`meta.${field._id}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        label={field.name}
      />
    );
  };

  const renderTextAreaField = (field: CustomField) => {
    try {
      var value = values.meta![field._id].toString();
    } catch (error) {
      value = "";
    }
    return (
      <Textarea
        key={field._id}
        name={`meta.${field._id}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        label={field.name}
      />
    );
  };

  const renderCheckboxField = (field: CustomField) => {
    // Ensure the selected value is a boolean for the checkbox
    const isSelected = !!values.meta?.[field._id]; // Convert the value to a boolean

    return (
      <div key={field._id}>
        <Checkbox
          classNames={{
            label: "text-small",
          }}
          name={`meta.${field._id}`}
          isSelected={isSelected} // Use the boolean value here
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {field.name}
        </Checkbox>
      </div>
    );
  };

  const renderDropdownField = (field: CustomField) => {
    if (!field.options || field.options.length === 0) {
      return null;
    }

    // Safely access opportunity.meta or provide a default empty string if undefined
    const selectedValue = values.meta?.[field._id] || "";
    return (
      <Select
        items={field.options}
        key={field._id}
        name={`meta.${field._id}`}
        label={field.name}
        placeholder="Select an option"
        onChange={handleChange}
        selectedKeys={[selectedValue.toString()]}
      >
        {(option) => <SelectItem key={option.value}>{option.label}</SelectItem>}
      </Select>
    );
  };

  const renderRadioField = (field: CustomField) => {
    if (!field.options || field.options.length === 0) {
      return null;
    }

    // Safely access opportunity.meta or provide a default empty string if undefined
    const selectedValue = values.meta?.[field._id] || "";

    return (
      <div key={field._id}>
        <div className="my-2 text-sm text-foreground-500">{field.name}</div>
        <RadioGroup
          size="sm"
          key={field._id}
          name={`meta.${field._id}`}
          value={selectedValue.toString()} // Use the safely retrieved value
          color="primary"
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {field.options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="space-y-4">
        {fields
          .filter((column) => showAllFields || (currentColumn && currentColumn._id === column._id))
          .map(
            (column) =>
              column.fields.length > 0 && (
                <div key={column.columnName} className="px-4 py-4 space-y-4 border rounded-lg border-card">
                  {currentColumn && currentColumn._id === column._id ? (
                    <div className="flex uppercase text-primary text-tiny">{column.columnName}</div>
                  ) : (
                    <div className="flex uppercase text-foreground-500 text-tiny">{column.columnName}</div>
                  )}
                  {column.fields.map((field: any) => {
                    if (!field) return null;
                    switch (field.type) {
                      case "text":
                        return renderTextField(field);
                      case "textarea":
                        return renderTextAreaField(field);
                      case "checkbox":
                        return renderCheckboxField(field);
                      case "dropdown":
                        return renderDropdownField(field);
                      case "radio":
                        return renderRadioField(field);
                      default:
                        return null;
                    }
                  })}
                </div>
              )
          )}
        {me.currentTeam.integrations?.oneflow?.enabled && <Oneflow />}
        {me.currentTeam.integrations?.rexor && !values.rexorId && <RexorProject />}
        <Button size="sm" className="w-32" onPress={() => setShowAllFields(!showAllFields)} variant="light">
          {showAllFields ? lang("Hide other fields") : lang("Show all fields")}
        </Button>
      </div>
    </div>
  );
}
