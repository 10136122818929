import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Formatter from "../../utils/Formatter";
import { UserContext } from "../../contexts/UserContext";
import { Selection } from "@heroui/react";

type ClosedValueProps = {
  tagsFilter: Selection;
};

export default function ClosedValue({ tagsFilter }: ClosedValueProps) {
  const { opportunities } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [previousValue, setPreviousValue] = useState(0);

  useEffect(() => {
    const todayTimestamp = new Date().getTime();
    const last30DaysStartTimestamp = todayTimestamp - 30 * 24 * 60 * 60 * 1000;
    const last60DaysStartTimestamp = todayTimestamp - 60 * 24 * 60 * 60 * 1000;

    const filteredOpportunities = opportunities.filter((opportunity) =>
      Array.from(tagsFilter).every((tag) => opportunity.tags?.includes(tag))
    );

    const totalCurrentPeriod = filteredOpportunities
      .filter(
        (opportunity) =>
          (opportunity.closedDate || 0) >= last30DaysStartTimestamp && (opportunity.closedDate || 0) <= todayTimestamp
      )
      .reduce((acc, curr) => acc + (curr.value || 0), 0);

    const totalPreviousPeriod = filteredOpportunities
      .filter(
        (opportunity) =>
          (opportunity.closedDate || 0) >= last60DaysStartTimestamp &&
          (opportunity.closedDate || 0) < last30DaysStartTimestamp
      )
      .reduce((acc, curr) => acc + (curr.value || 0), 0);

    setCurrentValue(totalCurrentPeriod);
    setPreviousValue(totalPreviousPeriod);
  }, [opportunities, tagsFilter]);

  const change = currentValue - previousValue;
  const percentageChangeNumber = previousValue !== 0 ? (change / previousValue) * 100 : 100;

  // In this example, displayPercentage is calculated differently.
  const displayPercentage = percentageChangeNumber > 0 ? percentageChangeNumber - 100 : percentageChangeNumber;
  const percentageChangeStr = displayPercentage.toFixed(2);

  const colorClass = displayPercentage > 0 ? "text-success" : displayPercentage < 0 ? "text-warning" : "text-neutral";

  return (
    <div className="flex flex-col h-full p-4 rounded-lg bg-content1 shadow-small">
      <div className="flex w-full h-10 mb-4 rounded-lg bg-card">
        <div className="my-auto ml-4 font-semibold uppercase text-foreground-500 text-tiny">
          {lang("Closed value last 30 days")}
        </div>
      </div>
      <div className="mx-auto text-4xl">{Formatter(currentValue)}</div>
      <div className="mx-auto text-base text-foreground-500">
        <span className={colorClass}>
          {displayPercentage > 0 ? `+${percentageChangeStr}%` : `${percentageChangeStr}%`}
        </span>
        {" " + lang("from previous period")}
      </div>
    </div>
  );
}
