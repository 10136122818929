export default function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-full h-screen text-gray-700 bg-white sm:flex font-poppins">
      <div className="flex flex-col w-full h-full p-8 light">
        {children}
        <div className="mx-auto text-sm text-gray-500">© 2024 Customer Connect CRM AB, All rights reserved.</div>
      </div>
      <div className="hidden w-full h-full py-10 pr-8 text-white lg:block">
        <div className="flex w-full h-full p-8 xl:px-20 bg-gradient-to-r from-card to-offblack rounded-2xl">
          <div className="flex flex-col my-auto space-y-4">
            <div className="max-w-3xl text-4xl font-medium">Empowering Businesses with AI-Driven Lead Management</div>
            <div className="max-w-2xl text-line">
              Customer Connect identifies business opportunities from emails and contact forms, allowing you to quickly
              and easily manage your leads and close more deals through your customized sales process.
            </div>
            <div>
              <img
                src="/ui.webp"
                alt="ui"
                className="object-contain my-10 border border-label border-opacity-30 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
